import shortid from "shortid";

import * as MUI from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as UI from "./components/elements/index.js";

export const DEBUG = false;

export const SIDEBAR_ITEM = "sidebarItem";
export const CONTAINER = "container";
export const COMPONENT = "component";
export const ROW = "row";
export const COLUMN = "column";

export const COL_WIDTH = 12; // 2
export const MAX_COLS = 2; // 12

export const CONTAINER_DICT = {
  block: UI.Grid,
  panel: UI.Panel,
  grid: UI.Grid,
  tabs: UI.Tabs,
  tab: UI.Tabs,
  tabBlock: UI.TabBlock,
  collection: UI.Collection,
};

export const CONTAINERS = Object.keys(CONTAINER_DICT);

export const COMPONENT_DICT = {
  textarea: UI.Textarea,
  cancel: UI.Cancel,
  menu: UI.Menu,
  submit: UI.Submit,
  number: UI.Number,
  string: UI.String,
  input: UI.Input,
  popup: UI.Popup,
  popupoption: UI.Popupoption,
  profile_picture: UI.Profile,
  checkbox: UI.Checkbox,
  subform: UI.Subform,
  listview: UI.Listview,
  select: UI.Select,
  currency: UI.Select,
  radio: UI.RadioGroup,
  javascript: UI.Javascript,
  file: UI.File,
};

export const SIDEBAR_ITEMS = [
  {
    id: shortid.generate(),
    template: "block",
    extraConf: {
      type: CONTAINER,
      direction: COLUMN, // Change back later
      maxCols: 4,
      colWidth: 6,
    },
  },
  {
    id: shortid.generate(),
    template: "panel",
    extraConf: {
      type: CONTAINER,
      direction: COLUMN,
    },
  },
  {
    id: shortid.generate(),
    template: "tab",
    extraConf: {
      type: CONTAINER,
      direction: ROW,
    },
  },
  {
    id: shortid.generate(),
    template: "grid",
    extraConf: {
      type: CONTAINER,
      direction: COLUMN,
      maxCols: 4,
      colWidth: 6,
    },
  },
  {
    id: shortid.generate(),
    template: "collection",
    extraConf: {
      type: CONTAINER,
      direction: ROW, // Change back later
      maxCols: 4,
      colWidth: 6,
    },
  },
  ...[...Object.keys(COMPONENT_DICT)].map((template) => ({
    //id: shortid.generate(),
    template: template,
    name: template,
    extraConf: {
      type: COMPONENT,
      direction: ROW,
    },
  })),
];

export const SPECIAL_DEFAULTS = {
  accordion: { summary: "Summary", details: "Details" },
  select: ["item 1", "item 2", "item 3"],
  popup: ["item 1", "item 2", "item 3"],
  radiogroup: ["item 1", "item 2", "item 3"],
  tabs: ["tab 1", "tab 2"],
};

export const DEFAULT_TRUE = [
  "empty",
  "inline_search",
  "select",
  "open",
  "is_web_compatible",
  "is_mobile_compatible",
  "compute_field_sequence",
  "allow_row_delete",
  "allow_row_create",
  "pfooter",
];

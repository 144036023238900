import React from "react";
import Button from "@mui/material/Button";
import { getName } from "../../helper";

const Cancel = ({ item }) => {
  return (
    <Button fullWidth color="error" variant="outlined">
      {getName(item, "Cancel")}
    </Button>
  );
};

export default Cancel;

import React from "react";
import Button from "@mui/material/Button";
import { getName } from "../../helper";
const Submit = ({ item }) => {
  return (
    <Button fullWidth variant="outlined">
      {getName(item, "Submit")}
    </Button>
  );
};

export default Submit;

import React from "react";
import { getName } from "../../helper";
import Skeleton from "@mui/material/Skeleton";

const Listview = ({ item }) => {
  return (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={100}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
   >
      List View: {item.properties.listview}
    </Skeleton>
  );
};

export default Listview;

import React, { useState, useEffect } from "react";
import { DEBUG } from "../../constants";
import MuiTabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styles from "../../styles";
import { getName } from "../../helper";

const Tabs = ({
  item,
  children,
}) => {
  const [index, setIndex] = useState(0);

  const { id, extraConf: { config, specialConfig } } = item;
  useEffect(() => {
    if (index >= item.children.length) {
      setIndex(item.children.length - 1);
    }
  }, [index, item.children]);

  /*  
  useEffect(() => {
    console.log(children);
    console.log(specialConfig);
  }, [children]);
  */

  return (
    <>
      {DEBUG && `TAB-${id}`}
      <MuiTabs
        variant="scrollable"
        scrollButtons="auto"
        value={index}
        onChange={(_, idx) => setIndex(idx)}
        {...config}
      >
        {item.children?.map((child, idx) => (
          <Tab
            key={`tab-${id}-${idx}`}
            label={getName(child, child.template)}
            id={`${id}-${idx}`}
          />
        ))}
      </MuiTabs>
      {item.children.map((child, idx) => (
        <div
          key={`div-${id}-${idx}`}
          style={{ width: "100%", height: "100%" }}
          hidden={index !== idx}
        >
          {index === idx && (
            <div style={styles.container}>
              {
                React.Children.toArray(
                  children[0].props.children[0][idx].props.children
                )[1]
              }
            </div>
          )}
        </div>
      ))}
    </>
  );
};
Tabs.displayName = "Tabs";
export default Tabs;

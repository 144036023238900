import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useContext,
} from "react";
import { MenuContext } from "../contexts/MenuContext";
import { LayoutContext } from "../contexts/LayoutContext";
import { useDrop } from "react-dnd";
import styles from "./BuildZone.module.css";
import DropZone from "./DropZone";
import Container from "./Container";
import Component from "./Component";
import Label from "./elements/Label";
import Menu from "./Menu";
import shortid from "shortid";
import Config from "./Config";
import FormMenu from "./FormMenu";
import Button from "@mui/material/Button";
import CreateConfig from "./configs/CreateConfig";
import {
  insertElement,
  moveElement,
  modifyElement,
  getElement,
  isGrid,
  getName,
  hasExternalLabel,
} from "../helper";
import {
  CONTAINER,
  COLUMN,
  SIDEBAR_ITEM,
  SIDEBAR_ITEMS,
  COL_WIDTH,
  MAX_COLS,
  SPECIAL_DEFAULTS,
} from "../constants";

const BuildZone = () => {
  // CONTEXT MENU
  const { showMenu, setShowMenu } = useContext(MenuContext);
  const { layout, setLayout } = useContext(LayoutContext);

  // used for the new form menu
  const [showFormMenu, setShowFormMenu] = useState(false);

  // these are used for the CreateConfig modal
  const [createState, setCreateState] = useState({});
  const [showCreate, setShowCreate] = useState(false);

  const handleClick = useCallback(
    () => showMenu && setShowMenu(false),
    [showMenu]
  );

  // add event listeners for the right click menu
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  // handles special insertion for tabs 
  const handleSpecialConfig = async (item, layout, path) => {
    if (item.template === "tabs") {
      layout = modifyElement(layout, path, "extraConf.specialConfig", [
        { name: "Tab 1", path: 0 },
        { name: "Tab 2", path: 1 },
      ]);
      [0, 1].forEach(async (i) => {
        layout = await insertElement(layout, `${path}-${i}`, {
          ...SIDEBAR_ITEMS[0],
          id: shortid.generate(),
          config: {},
          children: [],
        });
      });
    } else {
      layout = modifyElement(
        layout,
        path,
        "extraConf.specialConfig",
        SPECIAL_DEFAULTS[item.template]
      );
    }

    return layout;
  };

  // inserts an item from side bar given the item, path and properties
  const insertSidebarItem = async (item, path, parent, properties) => {
    // parent, item, path,
    let temp = await insertElement(layout, path, {
      ...item,
      id: shortid.generate(),
      properties: properties,
      children: [],
      extraConf: {
        ...item.extraConf,
        parentCols: parent.extraConf.maxCols,
        parentWidth: parent.extraConf.colWidth,
      },
    });
    temp = await handleSpecialConfig(item, temp, path);
    setLayout(temp);
  };

  // method called by the useDrop hook when elements are dropped
  const handleDrop = useCallback(
    async (item, path, type) => {
      console.log(item);
      const parent =
        getElement(layout, `${path.split("-").slice(0, -1).join("-")}`) ||
        layout;
      if (type === SIDEBAR_ITEM) {
        setCreateState({ item, path, parent });
        setShowCreate(true);
      } else {
        setLayout(await moveElement(layout, item.path, path));
      }
    },
    [layout]
  );

  useEffect(() => {
    console.log("Layout", layout);
  }, [layout]);

  // IMPORTANT FUNCTION
  // This function renders the based on the layout. It will render drop zones in between 
  // items. It also handles grid columns for grids.
  const renderLayout = (layout, path) => {
    // For grid
    let cols = 0;

    return (
      <>
        {layout.children.map((item, idx) => {
          // For grid
          let extraDrop = false;
          const colStart = cols;
          if (
            cols > 0 &&
            cols + item.properties.colspan > layout.extraConf.maxCols
          ) {
            extraDrop = true;
            cols = item.properties.colspan;
            //if(hasExternalLabel(item.template) && item.properties.colspan < layout.extraConf.maxCols) cols+=1;
          } else {
            cols += item.properties.colspan;
            //if(hasExternalLabel(item.template) && item.properties.colspan < layout.extraConf.maxCols) cols+=1;
          }

          return (
            <Fragment key={item.id}>
              <DropZone
                vertical={layout.extraConf.direction === COLUMN}
                path={`${path}${path && "-"}${idx}`}
                handleDrop={handleDrop}
                grid={isGrid(layout.template)}
                fillRest={isGrid(layout.template) && extraDrop}
                colStart={colStart * layout.extraConf.colWidth + colStart + 1}
              />
              {isGrid(layout.template) && extraDrop && (
                <DropZone
                  grid
                  vertical={layout.extraConf.direction === COLUMN}
                  path={`${path}${path && "-"}${idx}`}
                  handleDrop={handleDrop}
                />
              )}
              {/*isGrid(layout.template) && hasExternalLabel(item.template) && item.properties.colspan < layout.extraConf.maxCols && (
                <Label label={getName(item, "Input")} parentWidth={item.extraConf.parentWidth}/>
              )*/}
              {item.extraConf.type === CONTAINER ? (
                <Container
                  item={item}
                  handleDrop={handleDrop}
                  path={`${path}${path && "-"}${idx}`}
                >
                  {item.children.length > 0 &&
                    renderLayout(item, `${path}${path && "-"}${idx}`)}
                </Container>
              ) : (
                <Component
                  item={item}
                  cappedWidth={layout.template === "panel"}
                  path={`${path}${path && "-"}${idx}`}
                />
              )}
            </Fragment>
          );
        })}
        {layout.children.length > 0 && (
          <DropZone
            grid={isGrid(layout.template)}
            vertical={layout.extraConf.direction === COLUMN}
            path={`${path}${path && "-"}${layout.children.length}`}
            handleDrop={handleDrop}
            fillRest={isGrid(layout.template)}
            colStart={cols * layout.extraConf.colWidth + cols + 1}
          />
        )}
      </>
    );
  };

  return (
    <div className={styles.zone}>
      {layout ? (
        <>
          <div className={styles.container}>
            {layout.children.length <= 0 && (
              <DropZone filled path="0" handleDrop={handleDrop} />
            )}
            {renderLayout(layout, "")}
          </div>
          <Menu />
          <Config />
          {showCreate && (
            <CreateConfig
              createState={createState}
              showCreate={showCreate}
              setShowCreate={setShowCreate}
              insertSidebarItem={insertSidebarItem}
            />
          )}
        </>
      ) : (
        <div style={{ background: "#e4e7eb" }} className={styles.blank}>
          <Button variant="outlined" onClick={() => setShowFormMenu(true)}>
            New Form
          </Button>
          <FormMenu
            showFormMenu={showFormMenu}
            setShowFormMenu={setShowFormMenu}
          />
        </div>
      )}
    </div>
  );
};

export default BuildZone;

import React from "react";
import { DEBUG } from "../../constants";
import styles from "../../styles";

const TabBlock = ({ item: { id }, children }) => {
  return (
    <>
      {DEBUG && `TabBlock-${id}`}
      <div style={styles.container}>{children}</div>
    </>
  );
};
TabBlock.displayName = "TabBlock";
export default TabBlock;

import React, { useEffect } from "react";
import { DEBUG } from "../../constants";
import { getName } from "../../helper";
import styles from "../../styles";
import Typography from "@mui/material/Typography";

const Collection = ({ item, children }) => {
  return (
    <>
      {DEBUG && `Collection-${item.id}`}
      <div style={{ ...styles.container, flexDirection: "column" }}>
        {children}
      </div>
    </>
  );
};

Collection.displayName = "Collection";
export default Collection;

import React, { Fragment, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import { MenuContext } from "../../contexts/MenuContext";
import { LayoutContext } from "../../contexts/LayoutContext";

import { modifyElement } from "../../helper";

import Properties from "./Properties";

const PropertiesConfig = ({ element }) => {
  const { setShowConfig, path } = useContext(MenuContext);
  const { layout, setLayout } = useContext(LayoutContext);
  const [oldProperties, setOldProperties] = useState(element?.properties);
  const [properties, setProperties] = useState(element?.properties);
  const [propertyOptions, setPropertyOptions] = useState({});
  const [changed, setChanged] = useState(false);

  // {key: value} => [["key","value"]]
  useEffect(() => {
    setOldProperties(element.properties);
    setProperties(element.properties);
  }, [element]);

  useEffect(() => {
    let isChanged = false;
    Object.keys(properties).forEach((key, idx) => {
      if (properties[key] !== oldProperties[key]) {
        isChanged = true;
      }
    });
    setChanged(isChanged);
  }, [properties, oldProperties]);

  const save = () => {
    const parse = (string) => {
      var number = parseFloat(string);
      if (!isNaN(number)) return number;
      if (string.toLowerCase() === "true") return true;
      if (string.toLowerCase() === "false") return false;
      // try {
      //   var o = JSON.parse(string);
      //   if (o && typeof o === "object") {
      //     string = o;
      //   }
      // } catch (e) {}
      return string;
    };
    let parsed = {};
    Object.keys(properties).forEach((key) => {
      const prop = properties[key];
      parsed[key] = typeof prop === "object" ? prop : parse(prop);
    });
    setLayout(modifyElement(layout, path, "properties", parsed));
  };

  return (
    <>
      <Properties
        item={element}
        properties={properties}
        setProperties={setProperties}
        propertyOptions={propertyOptions}
        setPropertyOptions={setPropertyOptions}
      />
      <DialogActions>
        <Button
          color="primary"
          disabled={!changed}
          onClick={() => save()}
          autoFocus
        >
          Save
        </Button>
        <Button onClick={() => setShowConfig(false)} autoFocus>
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default PropertiesConfig;

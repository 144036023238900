import React, { useEffect, useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import { LayoutContext } from "../contexts/LayoutContext";
import * as api from "../api";
import { getFields } from "../helper";

const FormMenu = ({ showFormMenu, setShowFormMenu }) => {
  const { layout, setLayout } = useContext(LayoutContext);

  const [model, setModel] = useState("");
  const [models, setModels] = useState();
  const [name, setName] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => setModels(await api.fetchModels());

  const createForm = async () => {
    const data = await api.createForm(model, name);
    setLayout({
      id: data.id,
      model_class: model,
      children: [],
      extraConf: {
        direction: "row",
      },
      ...getFields(data.data.forms),
    });
    setShowFormMenu(false);
  };

  return (
    <Dialog
      open={showFormMenu}
      onClose={() => setShowFormMenu(false)}
      fullWidth
    >
      <DialogTitle>Create New Form</DialogTitle>
      <DialogContent>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={models}
                onChange={(_, val) => setModel(val)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Model Class" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => createForm()}>
          Create
        </Button>
        <Button variant="text" onClick={() => setShowFormMenu(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormMenu;

import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { getName } from "../../helper";

const Javascript = () => {
  return (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={100}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {"{ Javascript }"}
    </Skeleton>
  );
};

export default Javascript;

import React, { useEffect, useState, useContext, Fragment } from "react";
import { MenuContext } from "../../contexts/MenuContext";
import { LayoutContext } from "../../contexts/LayoutContext";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  removeElement,
  moveElement,
  insertElement,
  getName,
} from "../../helper";
import { SIDEBAR_ITEMS } from "../../constants";
import shortid from "shortid";

const TabConfig = ({ element }) => {
  const { setShowConfig, path } = useContext(MenuContext);
  const { layout, setLayout } = useContext(LayoutContext);

  const handleClick = async (direc, idx) => {
    if (direc === "up") {
      setLayout(
        await moveElement(layout, `${path}-${idx}`, `${path}-${idx - 1}`)
      );
    } else {
      setLayout(
        await moveElement(layout, `${path}-${idx}`, `${path}-${idx + 2}`)
      );
    }
  };

  const handleAdd = async () => {
    setLayout(
      await insertElement(layout, `${path}-${element.children.length}`, {
        ...SIDEBAR_ITEMS[0],
        properties: { colspan: 1 },
        id: shortid.generate(),
        children: [],
      })
    );
  };

  const handleDelete = async (idx) => {
    if (element.children.length > 1) {
      setLayout(await removeElement(layout, `${path}-${idx}`));
    }
  };

  return (
    <div>
      <p>Tab Settings</p>
      <Grid container spacing={1} alignItems="center">
        {element.children.map((child, idx) => (
          <Fragment key={idx}>
            <Grid item xs={11}>
              <TextField
                fullWidth
                value={getName(child, child.template)}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="delete panel"
                        onClick={() => {
                          handleDelete(idx);
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <IconButton
                  size="small"
                  sx={{ p: 0 }}
                  onClick={() => handleClick("up", idx)}
                  disabled={idx === 0}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                  size="small"
                  sx={{ p: 0 }}
                  onClick={() => handleClick("down", idx)}
                  disabled={idx === element.children.length - 1}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </div>
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <Button
        sx={{ mt: 2 }}
        variant="outlined"
        autoFocus
        onClick={() => handleAdd()}
      >
        Add
      </Button>
      <DialogActions>
        {/*<Button
          color="primary"
          disabled={!changed || errors}
          onClick={() => {
            save();
          }}
          autoFocus
        >
          Save
        </Button>*/}
        <Button onClick={() => setShowConfig(false)} autoFocus>
          Close
        </Button>
      </DialogActions>
    </div>
  );
};
TabConfig.displayName = "TabConfig";
export default TabConfig;

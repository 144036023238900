import React from "react";
import MuiCheckbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getName } from "../../helper";

const Checkbox = ({ item }) => {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          style={{alignSelf: "center"}}
          control={<MuiCheckbox defaultChecked />}
          label={getName(item, "")}
          helperText={item.properties?.help}
        />
      </FormGroup>
    </>
  );
};

export default Checkbox;

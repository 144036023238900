import React, { useState, Fragment } from "react";
import styles from "./Sidebar.module.css";
import ListForms from "./ListForms";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import { SIDEBAR_ITEM, SIDEBAR_ITEMS } from "../constants";
import { useDrag } from "react-dnd";
import logo from "../logo.png"

const Item = ({ item }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: SIDEBAR_ITEM,
    item: {
      // id: item.id,
      // template: item.template,
      // extraConf: {
      //   type: item.extraConf.type,
      //   direction: item.extraConf.direction,
      // },
      ...item,
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging }),
  });
  return (
    <div className={styles.item} ref={dragRef}>
      {item.template}
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const Sidebar = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={styles.container}>
      <img
        style={{ margin: 20 }}
        src={logo}//"https://api-builder.fiyge.com/img/logo.png"
        alt="logo"
      />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
          <Tab label="Import Forms" />
          <Tab label="Add fields" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} sx={{ padding: "0px" }}>
        <ListForms />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={0}>
          {SIDEBAR_ITEMS.map((item) => (
            <Grid item xs={6} key={item.id}>
              <Item item={item} />
            </Grid>
          ))}
        </Grid>
      </TabPanel>
    </div>
  );
};

export default Sidebar;

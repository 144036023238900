import { createContext, useState } from "react";
import shortid from "shortid";

const LayoutContext = createContext({
  layout: {},
  setLayout: () => {},
});

const LayoutProvider = ({ children }) => {
  const [layout, setLayout] = useState();
  return (
    <LayoutContext.Provider
      value={{
        layout,
        setLayout,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutProvider };

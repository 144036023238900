import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { getName } from "../../helper";

const Popup = ({ item }) => {
  const {
    extraConf: { specialConfig },
  } = item;
  const [value, setValue] = useState("");
  useEffect(() => {
    console.log(item, specialConfig);
  }, []);
  return (
    <Autocomplete
      fullWidth
      options={specialConfig}
      autoComplete="off"
      renderInput={(params) => (
        <TextField
          {...params}
          label={getName(item, "")}
          helperText={item.properties?.help}
        />
      )}
      onChange={(e) => setValue(e.target.value)}
    />
    /* {specialConfig.map((item) => (
        <MenuItem value={item}>{item}</MenuItem>
      ))}
    </Autocomplete> */
  );
};

export default Popup;

import React, { Fragment, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { MenuContext } from "../contexts/MenuContext";
import { LayoutContext } from "../contexts/LayoutContext";
import { getElement, modifyElement } from "../helper";

import FiygeConfig from "./configs/FiygeConfig";
import PropertiesConfig from "./configs/PropertiesConfig";
import TabConfig from "./configs/TabConfig";

const Config = () => {
  const { showConfig, setShowConfig, path } = useContext(MenuContext);
  const { layout, setLayout } = useContext(LayoutContext);
  const [element, setElement] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    console.log(layout, path);
    setElement(getElement(layout, path));
    /*if (path) {
      setParent(getElement(layout, path.split("-").slice(0, -1).join("-")));
    }*/
  }, [layout, path]);

  return (
    <Dialog
      //fullScreen={fullScreen}
      open={showConfig}
      onClose={() => setShowConfig(false)}
    >
      <DialogTitle>Configuration</DialogTitle>
      <DialogContent>
        <div
          style={{
            borderBottom: "1px solid #dddddd",
          }}
        >
          <Tabs value={tabIndex} onChange={(_, val) => setTabIndex(val)}>
            <Tab label="Properties" />
            <Tab label="Fiyge" />
            {element?.template === "tab" && <Tab label="Tabs" />}
          </Tabs>
        </div>
        {tabIndex === 0 && <PropertiesConfig element={element} />}
        {tabIndex === 1 && <FiygeConfig element={element} />}
        {tabIndex === 2 && <TabConfig element={element} />}
      </DialogContent>
    </Dialog>
  );
};

export default Config;

import React, { useEffect, useState, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CONTAINER, DEFAULT_TRUE } from "../../constants";
import * as api from "../../api";
import lookups from "../../lookups";
import { LayoutContext } from "../../contexts/LayoutContext";
import { getName } from "../../helper";
import PROPERTIES from "../../properties.json";
import TYPES from "../../types.json";
import CALLS from "../../calls.json";
import ListSubheader from "@mui/material/ListSubheader";

import Properties from "./Properties";

const CreateConfig = ({
  createState,
  showCreate,
  setShowCreate,
  insertSidebarItem,
}) => {
  const { item, path, parent } = createState;

  const [properties, setProperties] = useState({});
  const [propertyOptions, setPropertyOptions] = useState({});

  useEffect(() => {
    let properties = {};
    let options = {};

    const data = PROPERTIES[item.template];

    const getDefaultValue = (type, name) => {
      switch (type) {
        case "autocomplete":
        case "select":
        case "radio":
        case "string":
          return "";
        case "int":
          return undefined;
        case "checkbox":
          return DEFAULT_TRUE.includes(name) ? 1 : 0;
        default:
          break;
      }
    };

    data.forEach((prop) => {
      const types = TYPES[prop.name];
      if (types.length > 1) {
        types.forEach((type) => {
          if (properties[prop.name]) {
            properties[prop.name].push(getDefaultValue(type, prop.name));
            options[prop.name].push([]);
          } else {
            properties[prop.name] = [getDefaultValue(type, prop.name)];
            options[prop.name] = [[]];
          }
        });
      } else {
        properties[prop.name] = getDefaultValue(types[0], prop.name);
        options[prop.name] = [];
      }
    });

    setProperties(properties);
    setPropertyOptions(options);
  }, [item]);

  return (
    <Dialog open={showCreate} onClose={() => setShowCreate(false)} fullWidth>
      <DialogTitle>Drop New {createState.item.template}</DialogTitle>
      <DialogContent>
        <Properties
          item={item}
          properties={properties}
          setProperties={setProperties}
          propertyOptions={propertyOptions}
          setPropertyOptions={setPropertyOptions}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            // Processing
            let req = {};
            Object.keys(properties)?.forEach((key) => {
              if (typeof properties[key] === "object") {
                if (key === "listview") {
                  req[key] = properties[key][1];
                }
                if (key === "value") {
                  req[key] = properties[key][1];
                }
              } else if (properties[key]) {
                req[key] = properties[key];
              }
            });
            // End processing
            insertSidebarItem(item, path, parent, {
              ...properties,
              ...req,
            });
            setShowCreate(false);
          }}
        >
          Create
        </Button>
        <Button variant="text" onClick={() => setShowCreate(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateConfig;

import _ from "lodash";
import axios from "axios";
import * as api from "./api";
import {
  CONTAINERS,
  CONTAINER,
  COMPONENT,
  COLUMN,
  ROW,
  CONTAINER_DICT,
} from "./constants";
// HELPER FUNCTIONS

export const getName = (fiygeComponent, fallback) => {
  return (
    fiygeComponent?.properties?.label ||
    fiygeComponent?.label ||
    fiygeComponent?.name ||
    fallback
  );
};

export const isGrid = (template) => {
  const grids = ["grid", "block"];
  return grids.includes(template);
};

export const hasExternalLabel = (template) => {
  const valid = [
    "input",
    "textarea",
    "checkbox",
    "select",
    "number",
    "currency",
    "popup",
  ];
  return valid.includes(template);
};

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed); // inserting task in new index

  return result;
};

export const remove = (arr, index) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // part of the array after the specified index
  ...arr.slice(index + 1),
];

export const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

const adjustSequence = async (arr) => {
  // Adjust sequence numbers
  arr?.children?.forEach((child, idx) => {
    if (child.sequence != idx + 1) api.updateSequence(child.id, idx + 1);
  });
};

export const getElement = (layout, path) => {
  let insertPath = path.split("-");
  let root = _.cloneDeep(layout);
  let curr = root;
  insertPath.forEach((pathIndex) => {
    curr = curr.children[pathIndex];
  });
  return curr;
};

export const insertElement = async (layout, path, item, blockApi) => {
  let insertPath = path.split("-");
  let root = _.cloneDeep(layout);
  let curr = root;
  insertPath.forEach((pathIndex, idx) => {
    if (idx === insertPath.length - 1) {
      return;
    }
    curr = curr.children[pathIndex];
  });

  const id =
    !blockApi &&
    (await api.addForm(
      layout,
      item,
      curr.id,
      Number(insertPath.slice(-1)[0]) + 1
    ));

  curr.children = insert(
    curr.children,
    Number(insertPath[insertPath.length - 1]),
    {
      ...item,
      id: id || item.id,
      parent_id: curr.id,
      sequence: Number(insertPath.slice(-1)[0]) + 1,
    }
  );

  adjustSequence(curr);

  return root;
};

export const removeElement = async (layout, path, blockApi) => {
  let insertPath = path.split("-");
  let root = _.cloneDeep(layout);
  let curr = root;
  console.log(path);
  insertPath.forEach((pathIndex, idx) => {
    if (idx === insertPath.length - 1) {
      return;
    }
    curr = curr.children[pathIndex];
  });
  if (!blockApi) {
    const id = curr.children[Number(insertPath[insertPath.length - 1])].id;
    await api.removeForm(id);
  }
  curr.children = remove(
    curr.children,
    Number(insertPath[insertPath.length - 1])
  );

  adjustSequence(curr);

  return root;
};

export const findElementById = (node, id) => {
  console.log(node, id);
  if (node.id === id) return node;
  for (var n of node.children) {
    const res = findElementById(n, id);
    if (res) return res;
  }
};

export const moveElement = async (layout, oldPath, newPath) => {
  let op = oldPath.split("-");
  let np = newPath.split("-");

  let adjustedOP = [...op];
  let needAdjust = true;
  if (
    np.length === op.length &&
    np.length > 1 &&
    np[np.length - 2] !== op[op.length - 2]
  ) {
    console.log("yay");
  }
  // If moving to outer layer
  else if (np.length <= op.length) {
    console.log("outer layer");
    let lastIdx = np.length - 1;
    // If moving to left side
    console.log(np);
    console.log(np[lastIdx]);

    for (let i = 0; i < np.length; i++) {
      // if it splits to right, don't adjust
      if (Number(np[i]) > Number(op[i])) {
        needAdjust = false;
        break;
      }
      // if it splits to left but theres more to its path, don't adjust
      if (Number(np[i]) < Number(op[i]) && i < np.length - 1) {
        needAdjust = false;
        break;
      }
    }
    if (needAdjust) {
      console.log("need adjust");
      adjustedOP[lastIdx]++;
    }
  }

  let root = _.cloneDeep(layout);
  let curr = root;
  op.forEach((pathIndex) => {
    curr = curr.children[pathIndex];
  });
  // curr = element to be moved
  console.log(newPath, adjustedOP);
  const newLayout = await removeElement(
    await insertElement(layout, newPath, curr, true),
    adjustedOP.join("-"),
    true
  );

  let node = root;
  np.forEach((pathIndex, idx) => {
    if (idx === np.length - 1) return;
    node = node.children[pathIndex];
  });

  const parent1 = getElement(newLayout, adjustedOP.slice(0, -1).join("-"));
  const parent2 = findElementById(newLayout, node.id);

  const callApi = async () => {
    await api.moveForm(
      curr?.id,
      Number(np[np?.length - 1]) + 1,
      parent2?.id || layout?.id
    );
    // Adjust sequence numbers
    (parent1 || newLayout).children.forEach((child, idx) => {
      if (child.sequence != idx + 1) api.updateSequence(child.id, idx + 1);
    });
    // Adjust sequence numbers
    if (parent2?.id !== parent1?.id) {
      parent2?.children.forEach((child, idx) => {
        if (child.sequence != idx + 1) api.updateSequence(child.id, idx + 1);
      });
    }
  };

  // make our api calls async
  callApi();

  return newLayout;
};

export const modifyElement = (layout, path, key_path, value) => {
  let insertPath = path.split("-");
  let root = _.cloneDeep(layout);
  let curr = root;
  insertPath.forEach((pathIndex, idx) => {
    curr = curr.children[pathIndex];
  });
  const kp = key_path.split(".");
  let obj = curr;
  kp.forEach((key, idx) => {
    if (idx === kp.length - 1) return;
    obj = obj[key];
  });
  obj[kp[kp.length - 1]] = value;

  // SAVE ON FIYGE
  if (kp[0] === "properties") {
    api.updateFormProperties(curr.id, curr.properties);
  }

  return root;
};

// export const fetchSubForm = async (model_class, model_name) => {
//   const access_token = await api.login();
//   const query = encodeURIComponent(
//     JSON.stringify({ where: { "forms.model_class": model_class } })
//   );
//   const res = await axios({
//     method: "get",
//     url: `${api.base_url}/development_base/forms/index.json?q=${query}`,
//     headers: {
//       Authorization: `Bearer ${access_token}`,
//     },
//   });
//   const data = res.paginate.data;
//   let subform = null;
//   data.forEach((form) => {
//     const properties = JSON.parse(form.properties);
//     if (properties.name === model_name) {
//       subform = form;
//     }
//   });
//   return subform ? loadForm(subform.id, access_token) : undefined;
// };

const insertNode = (tree, target, source) => {
  if (tree["id"] === target) {
    tree["children"].push(source);
  } else {
    tree["children"].forEach((c) => {
      insertNode(c, target, source);
    });
  }
};

const postInject = async (node) => {
  const access_token = localStorage.getItem("access_token");
  if (node.template === "tab") {
    node.children.forEach((child, idx) => {
      node.extraConf.specialConfig.push({
        name: getName(child, "Tab"),
        path: idx,
      });
    });
  } else if (node.template === "popup") {
    const query = encodeURIComponent(
      JSON.stringify({
        fields: ["{{MODEL}}.{{DISPLAY_FIELD}} as item"],
        autocomplete: 1,
      })
    );
    if (node.children.length > 0) {
      const href = node.children[0].properties.href.split("?");
      const res = await api.request({
        method: "get",
        url: `${api.base_url}/${href[0]}.json?${
          href[1] ? href[1] + "&" : ""
        }q=${query}`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      console.log(node, res);
      const options = res?.data?.paginate?.data?.map((e) => e.item);
      node.extraConf.specialConfig = options || [];
    } else node.extraConf.specialConfig = [];
  } else if (node.template === "radio") {
    node.extraConf.specialConfig = node.children.map(
      (child) => child.properties.text
    );
  }

  node.children = node.children.sort(
    (a, b) => Number(a.sequence) - Number(b.sequence)
  );

  for (var n of node.children) {
    n.extraConf.parentCols = node.extraConf.maxCols;
    n.extraConf.parentWidth = node.extraConf.colWidth;
    await postInject(n, access_token);
  }
};

export const getFields = (node) => ({
  id: node.id,
  name: node.name,
  parent_id: node.parent_id,
  properties: node.properties,
  sequence: node.sequence,
  template: node.template,
  depth: node.depth,
  full_name: node.full_name,
  model_class: node.model_class,
});

export const loadForm = async (start) => {
  const access_token = localStorage.getItem("access_token");

  const query = encodeURIComponent(
    JSON.stringify({ fields: ["*"], with_recursive: { "forms.id": start } })
  );

  const res = await api.request({
    method: "get",
    url: `${api.base_url}/development_base/forms/index.json?q=${query}`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  let root = JSON.parse(JSON.stringify(res.data.paginate.data)).map(getFields);
  console.log("helper", root);
  if (root.properties) {
    root.properties = JSON.parse(root.properties);
    if (root.properties.rules) {
      root.properties.rules = JSON.stringify(root.properties.rules);
    }
  }
  let tree = root[0];
  tree["children"] = [];
  if (tree.properties) {
    tree.properties = JSON.parse(tree.properties);
    if (tree.properties.rules) {
      tree.properties.rules = JSON.stringify(tree.properties.rules);
    }
  }
  tree.extraConf = { direction: "row", maxCols: 1, colWidth: 24 };
  root.forEach((c, idx) => {
    if (idx === 0) return;
    let newC = JSON.parse(JSON.stringify(c));
    newC["children"] = [];
    if (newC.properties) {
      newC.properties = JSON.parse(newC.properties);
      if (newC.properties.rules) {
        newC.properties.rules = JSON.stringify(newC.properties.rules);
      }
    }

    // MID-INJECTION
    newC.extraConf = {};
    if (CONTAINERS.includes(newC.template)) newC.extraConf.type = CONTAINER;
    else newC.extraConf.type = COMPONENT;
    newC.extraConf.config = { sx: {} };
    newC.extraConf.specialConfig = [];
    const columns = ["panel", "grid", "block"];
    newC.extraConf.direction = columns.includes(newC.template) ? COLUMN : ROW;
    if (newC.properties.cols) {
      newC.extraConf.maxCols = Number(newC.properties.cols);
      newC.extraConf.colWidth = Math.floor(24 / Number(newC.properties.cols));
    } else if (newC.template === "grid" || newC.template === "block") {
      newC.extraConf.maxCols = 4;
      newC.extraConf.colWidth = 6;
    }
    if (newC.properties.colspan) {
      newC.properties.colspan = Number(newC.properties.colspan);
    } else {
      newC.properties.colspan = 1;
    }
    // END MID-INJECTION

    insertNode(tree, newC["parent_id"], newC);
  });
  await postInject(tree, access_token);
  return tree;
};

import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "../contexts/MenuContext";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import Typography from "@mui/material/Typography";
import { useDrag } from "react-dnd";
import {
  COMPONENT,
  COL_WIDTH,
  CUSTOM_DICT,
  COMPONENT_DICT,
} from "../constants";
import Label from "./elements/Label";
import { getName } from "../helper";

const Component = ({ item, path, cantDrag, cappedWidth }) => {
  const { setShowMenu, setAnchorPoint, setPath } = useContext(MenuContext);
  
  const {
    template,
    id,
    properties: { tooltip, colspan },
    extraConf: { config, specialConfig, parentWidth },
  } = item;

  const [style, setStyle] = useState(defaultStyle);
  const [{ isDragging }, dragRef] = useDrag({
    type: COMPONENT,
    item: { type: COMPONENT, id: id, path },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (monitor) => !cantDrag,
  });

  const opacity = isDragging ? 0 : 1;

  const Element = COMPONENT_DICT[template];

  return (
    <div
      ref={dragRef}
      style={{
        ...style,
        maxWidth: cappedWidth && "250px",
        gridColumn: `auto / span ${colspan * parentWidth + colspan - 1}`,
        display: 'flex',
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        console.log(`${template}-${id}`);
        setShowMenu(true);
        setAnchorPoint({ x: e.pageX, y: e.pageY });
        setPath(path);
      }}
    >
      {/*<Label label={getName(item, item.template)}/>*/}
      {/*<Typography>{item.sequence}</Typography>*/}
      <Element item={item} setStyle={setStyle} />
      {tooltip && (
        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: tooltip }} />}>
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

const defaultStyle = {
  width: "100%",
  background: "#fff",
  cursor: "move",
  gridRow: "auto / span 1",
};

export default Component;

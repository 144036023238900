import React, { useState } from "react";
import MuiMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

const Menu = ({ item }) => {
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        More
      </Button>
      <MuiMenu anchorEl={anchor} open={open} onClose={() => setAnchor(null)}>
        {["Delete", "Clone"].map((item, idx) => (
          <MenuItem key={idx} value={item} onClick={() => setAnchor(null)}>
            {item}
          </MenuItem>
        ))}
      </MuiMenu>
    </>
  );
};

export default Menu;

import React, { useEffect, useState, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DEFAULT_TRUE } from "../../constants";
import * as api from "../../api";
import lookups from "../../lookups";
import { LayoutContext } from "../../contexts/LayoutContext";
import PROPERTIES from "../../properties.json";
import TYPES from "../../types.json";
import CALLS from "../../calls.json";
import { Typography } from "@mui/material";

const Properties = ({
  item,
  properties,
  setProperties,
  propertyOptions,
  setPropertyOptions,
}) => {
  const { layout } = useContext(LayoutContext);

  useEffect(() => {
    let options = {};

    const data = PROPERTIES[item.template];

    data.forEach((prop) => {
      const types = TYPES[prop.name];
      if (types.length > 1) {
        types.forEach((type) => {
          if (options[prop.name]) {
            options[prop.name].push([]);
          } else {
            options[prop.name] = [[]];
          }
        });
      } else {
        options[prop.name] = [];
      }
    });

    setPropertyOptions(options);
  }, [item]);

  /*  
    Name = autocomplete
    href = string
    value = dropdown + autocomplete
    text = string
    primary key = dropdown
    subform model class = dropdown
    subform name = dropdown
    listview = autocomplete
  */
  useEffect(() => {
    PROPERTIES[item.template]?.forEach((property) => {
      let arr = [];
      switch (property.name) {
        case "name":
        case "module_id":
          api
            .getItems(
              CALLS[property.name].href,
              CALLS[property.name].q,
              property.name === "name"
                ? layout.properties.model_class || layout.model_class
                : undefined
            )
            .then((res) => {
              console.log(res);
              setPropertyOptions((prev) => ({ ...prev, [property.name]: res }));
            });
          break;
        case "primary_key":
        case "model":
          api.formLookup(CALLS[property.name].lookup, layout.id).then((res) => {
            console.log(res);
            setPropertyOptions((prev) => ({
              ...prev,
              [property.name]: res?.map((item) => item.value),
            }));
          });
          break;
        case "sub_form_model_class":
          arr = [];
          Object.keys(lookups.models)?.forEach((key) => {
            lookups.models[key]?.forEach((model) => {
              arr.push(model.value);
            });
          });
          setPropertyOptions((prev) => ({
            ...prev,
            sub_form_model_class: arr,
          }));
          break;
        case "editor":
        case "permission":
          setPropertyOptions((prev) => ({
            ...prev,
            [property.name]: lookups[CALLS[property.name].lookup],
          }));
          break;
        // WEIRD CASES VV
        case "listview":
          api.getControllers().then((res) => {
            setPropertyOptions((prev) => ({
              ...prev,
              [property.name]: [res, prev[property.name][1]],
            }));
          });
          break;
        case "value":
          arr = [];
          Object.keys(lookups.value_popupoptions)?.forEach((key) => {
            lookups.value_popupoptions[key]?.forEach((option) => {
              arr.push({ text: option.text, value: option.href });
            });
          });
          setPropertyOptions((prev) => ({
            ...prev,
            [property.name]: [arr, prev[property.name][1]],
          }));
          break;
        default:
      }
      if (TYPES[property.name].includes("radio")) {
        setPropertyOptions((prev) => ({
          ...prev,
          [property.name]: lookups[`radio_${property.name}`],
        }));
      }
    });
  }, []);

  // special conf options
  useEffect(() => {
    if (properties["sub_form_model_class"]) {
      api.subformLookup(properties["sub_form_model_class"]).then((res) => {
        setPropertyOptions((prev) => ({ ...prev, sub_form_name: res }));
      });
    }
  }, [properties["sub_form_model_class"]]);

  useEffect(() => {
    if (properties["listview"]) {
      api.getListviews(properties["listview"][0]).then((res) => {
        setPropertyOptions((prev) => ({
          ...prev,
          listview: [prev["listview"][0], res],
        }));
      });
    }
  }, [properties["listview"]]);

  useEffect(() => {
    console.log(properties["value"]);
    if (properties["value"] && properties["value"][0]) {
      api.getItems(properties["value"][0] + ".json").then((res) => {
        setPropertyOptions((prev) => ({
          ...prev,
          value: [prev["value"][0], res],
        }));
      });
    }
  }, [properties["value"]]);
  useEffect(() => {
    console.log(properties);
  }, [properties]);

  const renderProperty = (property) => {
    const type = TYPES[property.name];
    if (type.length > 1) {
      if (property.name === "listview") {
        return (
          <>
            <Grid item xs={12}>
              <Autocomplete
                autoSelect
                value={
                  properties[property.name] ? properties[property.name][0] : ""
                }
                options={
                  propertyOptions[property.name]
                    ? propertyOptions[property.name][0]
                    : []
                }
                autoComplete="off"
                renderInput={(params) => (
                  <TextField {...params} label="Controller" />
                )}
                onChange={(_, val) => {
                  console.log(val);
                  setProperties((prev) => ({
                    ...prev,
                    [property.name]: [val, prev[property.name][1]],
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Listview</InputLabel>
                <Select
                  value={
                    properties[property.name]
                      ? properties[property.name][1]
                      : ""
                  }
                  fullWidth
                  label="Listview"
                  onChange={(e) => {
                    setProperties((prev) => ({
                      ...prev,
                      [property.name]: [prev[property.name][0], e.target.value],
                    }));
                  }}
                >
                  {propertyOptions[property.name] &&
                    propertyOptions[property.name][1]?.map((item, idx) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  {propertyOptions[property.name] &&
                    propertyOptions[property.name][1]?.length === 0 && (
                      <MenuItem disabled value="null">
                        No Items
                      </MenuItem>
                    )}
                </Select>
              </FormControl>
            </Grid>
          </>
        );
      }
      if (property.name === "value") {
        return (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Module</InputLabel>
              <Select
                value={
                  properties[property.name] ? properties[property.name][0] : ""
                }
                fullWidth
                label="Module"
                onChange={(e) => {
                  setProperties((prev) => ({
                    ...prev,
                    [property.name]: [e.target.value, prev[property.name][1]],
                  }));
                }}
              >
                {propertyOptions[property.name] &&
                  propertyOptions[property.name][0]?.map((item, idx) => (
                    <MenuItem value={item.value}>{item.text}</MenuItem>
                  ))}
                {propertyOptions[property.name] &&
                  propertyOptions[property.name][0]?.length === 0 && (
                    <MenuItem disabled value="null">
                      No Items
                    </MenuItem>
                  )}
              </Select>
            </FormControl>
            <Autocomplete
              freeSolo
              autoSelect
              value={
                properties[property.name] ? properties[property.name][1] : ""
              }
              options={
                propertyOptions[property.name]
                  ? propertyOptions[property.name][1]
                  : []
              }
              autoComplete="off"
              renderInput={(params) => <TextField {...params} label="Value" />}
              onChange={(_, val) => {
                setProperties((prev) => ({
                  ...prev,
                  [property.name]: [properties[property.name][0], val],
                }));
              }}
            />
          </Grid>
        );
      }
    } else {
      if (type[0] === "autocomplete") {
        return (
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              autoSelect
              required={property.required}
              value={properties[property.name]}
              options={propertyOptions[property.name]}
              autoComplete="off"
              renderInput={(params) => (
                <TextField {...params} label={property.label} />
              )}
              onChange={(_, val) => {
                console.log(val);
                setProperties((prev) => ({
                  ...prev,
                  [property.name]: val,
                }));
              }}
            />
          </Grid>
        );
      }
      if (type[0] === "string") {
        return (
          <Grid item xs={12}>
            <TextField
              label={property.label}
              fullWidth
              required={property.required}
              value={properties[property.name]}
              multiline={property.name === "javascript"}
              rows={property.name === "javascript" && 3}
              onChange={(e) => {
                console.log(e.target.value);
                setProperties((prev) => ({
                  ...prev,
                  [property.name]: e.target.value,
                }));
              }}
            />
          </Grid>
        );
      }
      if (type[0] === "checkbox") {
        return (
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                // style={{ alignSelf: "center" }}
                control={
                  <Checkbox
                    checked={properties[property.name] === 1}
                    onChange={(e) =>
                      setProperties((prev) => ({
                        ...prev,
                        [property.name]: e.target.checked ? 1 : 0,
                      }))
                    }
                  />
                }
                label={property.label}
              />
            </FormGroup>
          </Grid>
        );
      }
      if (type[0] === "int") {
        return (
          <Grid item xs={12}>
            <TextField
              label={property.label}
              fullWidth
              type="number"
              required={property.required}
              value={properties[property.name]}
              onChange={(e) => {
                console.log(e.target.value);
                setProperties((prev) => ({
                  ...prev,
                  [property.name]: Number(e.target.value),
                }));
              }}
            />
          </Grid>
        );
      }
      if (type[0] === "select") {
        return (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>{property.label}</InputLabel>
              <Select
                value={properties[property]}
                fullWidth
                label={property.label}
                onChange={(e) => {
                  console.log(e.target.value);
                  setProperties((prev) => ({
                    ...prev,
                    [property.name]: e.target.value,
                  }));
                }}
              >
                {propertyOptions[property.name]?.map((item, idx) =>
                  typeof item === "object" ? (
                    <MenuItem value={item.value}>{item.text}</MenuItem>
                  ) : (
                    <MenuItem value={item}>{item}</MenuItem>
                  )
                )}
                {propertyOptions[property.name]?.length === 0 && (
                  <MenuItem disabled value="null">
                    No Items
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        );
      }
      if (type[0] === "radio") {
        return (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>{property.label}</FormLabel>
              <RadioGroup row>
                {propertyOptions[property.name]?.map((item) => (
                  <FormControlLabel
                    value={item.value}
                    control={<Radio />}
                    label={item.text}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        );
      }
    }
    return <>{property.label}</>;
  };

  return (
    <Grid sx={{ mt: 1 }} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Basic</Typography>
      </Grid>
      {PROPERTIES[item.template]
        ?.filter((property) => property.category === "basic")
        .map((property) => renderProperty(property))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Lookups</Typography>
      </Grid>
      {PROPERTIES[item.template]
        ?.filter((property) => property.category === "lookup")
        .map((property) => renderProperty(property))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Boolean</Typography>
      </Grid>
      {PROPERTIES[item.template]
        ?.filter((property) => property.category === "boolean")
        .map((property) => renderProperty(property))}
    </Grid>
  );
};

export default Properties;

import React from "react";
import TextField from "@mui/material/TextField";
import { getName } from "../../helper";

const Number = ({ item }) => {
  return (
    <TextField
      fullWidth
      helperText={item.properties?.help}
      autoComplete="off"
      type="number"
      disabled={item.properties?.disabled}
      label={getName(item, "Input")}
      placeholder={item.properties?.value}
    />
  );
};

export default Number;

import axios from "axios";
// TODO: replace back to https://api-builder.fiyge.com
// export const base_url = "https://citiaxis.fiyge.com";
export const base_url = "https://api-builder.fiyge.com";

export const request = async (params) => {
  let res = await axios(params);
  console.log(res.data);
  if (res.data.errors.length > 0) {
    const refresh_token = localStorage.getItem("refresh_token");
    if (refresh_token) {
      const token = await axios({
        method: "GET",
        url: `${base_url}/access_controls/users/access_token.json?refresh_token=${encodeURIComponent(
          refresh_token
        )}`,
      });
      localStorage.setItem("access_token", token.data.access_token || "");
      localStorage.setItem("refresh_token", token.data.refresh_token || "");
      res = await axios({
        ...params,
        headers: {
          ...params.headers,
          Authorization: `Bearer ${token.data.access_token}`,
        },
      });
    } else {
      localStorage.setItem("access_token", "");
      localStorage.setItem("refresh_token", "");
    }
  }

  return res;
};

export const login = async (user_name, user_password) => {
  const res = await request({
    method: "post",
    url:
      base_url +
      `/access_controls/users/login.json?data[users][user_name]=${user_name}&data[users][user_password]=${user_password}`,
    // url:
    //   base_url +
    //   "/access_controls/users/login.json?data[users][user_name]=admin@citiaxis.fiyge.com&data[users][user_password]=admin@citiaxis.fiyge.com",
  });
  if (res.data.access_token && res.data.refresh_token) {
    localStorage.setItem("access_token", res.data.access_token);
    localStorage.setItem("refresh_token", res.data.refresh_token);
  }
  return res.data;
};

export const fetchForms = async () => {
  const access_token = localStorage.getItem("access_token");

  const query = {
    limit: -1,
  };
  const encoded = encodeURIComponent(JSON.stringify(query));

  const res = await request({
    method: "GET",
    url: `${base_url}/development_base/forms/index.json?q=${encoded}`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  return res.data.paginate.data.map((x, idx) => ({
    idx: idx,
    name: x["forms.name"],
    id: x["forms.id"],
  }));
};

export const addForm = async (layout, item, parent_id, seq) => {
  const access_token = localStorage.getItem("access_token");

  let formData = new FormData();
  formData.append("data[forms][template]", item.template);
  formData.append("data[forms][parent_id]", parent_id);
  formData.append("data[forms][properties][helper]", "\\kernel\\form");
  formData.append("data[forms][properties][method]", item.template);
  Object.keys(item.properties).forEach((key) => {
    if (key === "rules") {
      const rules = JSON.parse(item.properties[key]);
      rules.forEach((rule, idx) => {
        Object.keys(rule).forEach((key) => {
          formData.append(
            `data[forms][properties][rules][${idx}][${key}]`,
            rule[key]
          );
        });
      });
    } else {
      formData.append(`data[forms][properties][${key}]`, item.properties[key]);
    }
  });
  formData.append("data[forms][sequence]", seq);
  const res = await request({
    method: "POST",
    url: base_url + "/development_base/forms/add.json",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${access_token}`,
    },
    data: formData,
  });
  console.log("addForm");
  return res.data.id;
};

export const updateSequence = async (id, seq) => {
  const access_token = localStorage.getItem("access_token");
  let formData = new FormData();
  formData.append("data[forms][id]", id);
  formData.append("data[forms][sequence]", seq);
  const res = await request({
    method: "POST",
    url: base_url + "/development_base/forms/edit.json",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${access_token}`,
    },
    data: formData,
  });
  console.log("updateSeq");
  console.log(res);
};

export const removeForm = async (id) => {
  const access_token = localStorage.getItem("access_token");

  const res = await request({
    method: "GET",
    url: `${base_url}/development_base/forms/delete.json/is_confirm:1?id=${encodeURIComponent(
      id
    )}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${access_token}`,
    },
  });
  console.log(res);
};

export const moveForm = async (id, seq, parent_id) => {
  const access_token = localStorage.getItem("access_token");

  let formData = new FormData();
  formData.append("data[forms][id]", id);
  formData.append("data[forms][sequence]", seq);
  formData.append("data[forms][parent_id]", parent_id);
  const res = await request({
    method: "POST",
    url: base_url + "/development_base/forms/edit.json",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${access_token}`,
    },
    data: formData,
  });
  console.log(res);
};

export const fetchModels = async () => {
  const access_token = localStorage.getItem("access_token");

  const query = encodeURIComponent(
    JSON.stringify({
      limit: -1,
    })
  );

  const res = await request({
    method: "GET",
    url: `${base_url}/development_base/models/index.json?q=${query}`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  console.log(res.data);
  const parsed = res.data.paginate.data.map((x) => x["models.name"]);

  return parsed;
};

export const createForm = async (model, name) => {
  const access_token = localStorage.getItem("access_token");

  let formData = new FormData();
  formData.append("data[forms][model_class]", model);
  formData.append("data[forms][template]", "form");
  formData.append("data[forms][properties][name]", name);
  const res = await request({
    method: "POST",
    url: base_url + "/development_base/forms/add.json",
    data: formData,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};

export const updateForm = async (model, name) => {
  const access_token = localStorage.getItem("access_token");
  let formData = new FormData();
  formData.append("data[forms][model_class]", model);
  formData.append("data[forms][template]", "form");
  formData.append("data[forms][properties][name]", name);
  const res = await axios({
    method: "POST",
    url: base_url + "/development_base/forms/add.json",
    data: formData,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data.id;
};

export const updateFormProperties = async (id, values) => {
  const access_token = localStorage.getItem("access_token");
  let formData = new FormData();
  formData.append(`data[forms][id]`, id);
  Object.keys(values).forEach((key) => {
    if (key === "rules") {
      const rules = JSON.parse(values[key]);
      rules.forEach((rule, idx) => {
        Object.keys(rule).forEach((key) => {
          formData.append(
            `data[forms][properties][rules][${idx}][${key}]`,
            rule[key]
          );
        });
      });
    } else {
      formData.append(`data[forms][properties][${key}]`, values[key]);
    }
  });
  // formData.append(`data[forms][properties]`, JSON.stringify(values));
  const res = await request({
    method: "POST",
    url: base_url + "/development_base/forms/edit.json",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${access_token}`,
    },
    data: formData,
  });
  console.log(res);
};

export const getItems = async (href, q, model_class) => {
  const access_token = localStorage.getItem("access_token");
  let query = {
    fields: ["{{MODEL}}.{{DISPLAY_FIELD}} as item"],
    autocomplete: 1,
    fetch: 1,
    ...q,
  };
  if (model_class) {
    query.fetch_models_of_model = model_class;
    query.model_class = model_class;
  }
  query = encodeURIComponent(JSON.stringify(query));
  const res = await request({
    method: "GET",
    url: base_url + `/${href}?q=${query}`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data.paginate.data.map((field) =>
    model_class ? field.id : field.item
  );
};

export const formLookup = async (lookup, parent_id) => {
  const access_token = localStorage.getItem("access_token");
  const res = await request({
    method: "GET",
    url:
      base_url +
      `/development_base/forms/add.json?related_id=${parent_id}&related_model=parentForm`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data[lookup];
};

export const subformLookup = async (model_class) => {
  const access_token = localStorage.getItem("access_token");
  let formData = new FormData();
  formData.append("data[forms][properties][sub_form_model_class]", model_class);
  const res = await request({
    method: "POST",
    url: base_url + "/development_base/forms/add.json",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    data: formData,
  });
  return Object.keys(res.data["form_list"]);
};

export const getControllers = async () => {
  const access_token = localStorage.getItem("access_token");
  const query = encodeURIComponent(
    JSON.stringify({
      limit: -1,
    })
  );
  const res = await request({
    method: "GET",
    url:
      base_url +
      `/core/listviews/index.json?q=${query}&current_listview=System View List`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data.paginate.data.map((field) => field.Controller);
};

export const getListviews = async (controller) => {
  const access_token = localStorage.getItem("access_token");
  const query = encodeURIComponent(
    JSON.stringify({
      where: [{ "listviews.controller": controller }],
      active_level: 1,
    })
  );
  const res = await request({
    method: "GET",
    url: base_url + `/core/listviews/index.json?q=${query}`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data.paginate.data.map((field) => ({
    name: field["View Name"],
    id: field["listviews.id"],
  }));
};

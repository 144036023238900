import React, { useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Sidebar from "../components/Sidebar";
import BuildZone from "../components/BuildZone";
import styles from "./Builder.module.css";
import { MenuProvider } from "../contexts/MenuContext";
import { LayoutProvider } from "../contexts/LayoutContext";
import { useNavigate } from "react-router-dom";
const Builder = () => {
  let navigate = useNavigate();

  useEffect(() => {
    const checkTokens = () => {
      const access_token = localStorage.getItem("access_token");
      const refresh_token = localStorage.getItem("refresh_token");
      console.log(access_token, refresh_token);
      if (!access_token || !refresh_token) {
        navigate("/login");
      }
    };
    window.addEventListener("storage", checkTokens);
    checkTokens();
    return () => {
      window.removeEventListener("storage", checkTokens);
    };
  }, []);

  return (
    <div className={styles.App}>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.mainContainer}>
          <LayoutProvider>
            <MenuProvider>
              <Sidebar />
              <BuildZone />
            </MenuProvider>
          </LayoutProvider>
        </div>
      </DndProvider>
    </div>
  );
};

export default Builder;

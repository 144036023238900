import React, { useState } from "react";
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import { getName } from "../../helper";
import lookups from "../../lookups";

const Select = ({ item }) => {
  const [value, setValue] = useState(
    item.extraConf.specialConfig.length > 0
      ? item.extraConf.specialConfig[0]
      : ""
  );

  return (
    <FormControl fullWidth>
      <InputLabel>{getName(item, "Select")}</InputLabel>
      <MuiSelect
        value={value}
        label={getName(item, "Select")}
        onChange={(e) => setValue(e.target.value)}
        helperText={item.properties?.help}
      >
        {lookups[item.properties?.lookup]?.map((item, idx) => (
          <MenuItem value={item}>{item}</MenuItem>
        ))}
        {(lookups[item.properties?.lookup]?.length === 0 ||
          !lookups[item.properties?.lookup]) && (
          <MenuItem disabled value="null">
            No Items
          </MenuItem>
        )}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;

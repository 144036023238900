import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

const File = () => {
  const [file, setFile] = useState("");
  return (
    <div>
      <Button variant="contained" component="label">
        Upload File
        <input
          type="file"
          hidden
          onChange={(e) => {
            setFile(e.target.files[0].name);
          }}
        />
      </Button>
      <Typography>{file || "No file selected"}</Typography>
    </div>
  );
};

export default File;

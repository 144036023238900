import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { LayoutContext } from "../contexts/LayoutContext";
import { Button, TextField, IconButton, Grid } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { loadForm } from "../helper";
import { fetchForms } from "../api";

const ListForms = () => {
  const { layout, setLayout } = useContext(LayoutContext);
  const [form, setForm] = useState("");
  const [forms, setForms] = useState([]);

  useEffect(() => {
    const fetchData = async () => setForms(await fetchForms());
    fetchData();
  }, []);
   
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 380,
        bgcolor: "background.paper",
      }}
    >
      <Grid fullWidth container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Autocomplete
            options={forms}
            getOptionLabel={(x) => (x.name && `${x.idx + 1}. ${x.name}`) || ""}
            onChange={(_, val) => setForm(val)}
            renderInput={(params) => (
              <TextField {...params} label="Fiyge Forms" />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              console.log("form", form);
              loadForm(form.id).then((res) => setLayout(res));
            }}
          >
            Load
            {/*<DownloadIcon />*/}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setLayout(undefined)}
            disabled={!layout}
          >
            Close
            {/*<DownloadIcon />*/}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListForms;

import React, { useEffect } from "react";
import { DEBUG } from "../../constants";
import styles from "../../styles";

const Panel = ({ item: { id }, children, setStyle }) => {
  useEffect(() => {
    setStyle((old) => ({
      ...old,
      minHeight: "100px",
    }));
  }, []);

  return (
    <>
      {DEBUG && `PANEL-${id}`}
      <div
        style={{
          ...styles.container,
          flexDirection: "row",
        }}
      >
        {children}
      </div>
    </>
  );
};
Panel.displayName = "Panel";
export default Panel;

import React from "react";
import TextField from "@mui/material/TextField";
import { getName } from "../../helper";

const String = ({ item }) => {
  return (
    <TextField
      fullWidth
      autoComplete="off"
      disabled={item.properties?.disabled}
      label={getName(item, "Input")}
      placeholder={item.properties?.value}
    />
  );
};

export default String;

const lookups = {
  timezones: [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    "Africa/Bangui",
    "Africa/Banjul",
    "Africa/Bissau",
    "Africa/Blantyre",
    "Africa/Brazzaville",
    "Africa/Bujumbura",
    "Africa/Cairo",
    "Africa/Casablanca",
    "Africa/Ceuta",
    "Africa/Conakry",
    "Africa/Dakar",
    "Africa/Dar_es_Salaam",
    "Africa/Djibouti",
    "Africa/Douala",
    "Africa/El_Aaiun",
    "Africa/Freetown",
    "Africa/Gaborone",
    "Africa/Harare",
    "Africa/Johannesburg",
    "Africa/Juba",
    "Africa/Kampala",
    "Africa/Khartoum",
    "Africa/Kigali",
    "Africa/Kinshasa",
    "Africa/Lagos",
    "Africa/Libreville",
    "Africa/Lome",
    "Africa/Luanda",
    "Africa/Lubumbashi",
    "Africa/Lusaka",
    "Africa/Malabo",
    "Africa/Maputo",
    "Africa/Maseru",
    "Africa/Mbabane",
    "Africa/Mogadishu",
    "Africa/Monrovia",
    "Africa/Nairobi",
    "Africa/Ndjamena",
    "Africa/Niamey",
    "Africa/Nouakchott",
    "Africa/Ouagadougou",
    "Africa/Porto-Novo",
    "Africa/Sao_Tome",
    "Africa/Tripoli",
    "Africa/Tunis",
    "Africa/Windhoek",
    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Fort_Nelson",
    "America/Fortaleza",
    "America/Glace_Bay",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nipigon",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Nuuk",
    "America/Ojinaga",
    "America/Panama",
    "America/Pangnirtung",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port-au-Prince",
    "America/Port_of_Spain",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Punta_Arenas",
    "America/Rainy_River",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Thunder_Bay",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "America/Yellowknife",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Macquarie",
    "Antarctica/Mawson",
    "Antarctica/McMurdo",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Arctic/Longyearbyen",
    "Asia/Aden",
    "Asia/Almaty",
    "Asia/Amman",
    "Asia/Anadyr",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Ashgabat",
    "Asia/Atyrau",
    "Asia/Baghdad",
    "Asia/Bahrain",
    "Asia/Baku",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Beirut",
    "Asia/Bishkek",
    "Asia/Brunei",
    "Asia/Chita",
    "Asia/Choibalsan",
    "Asia/Colombo",
    "Asia/Damascus",
    "Asia/Dhaka",
    "Asia/Dili",
    "Asia/Dubai",
    "Asia/Dushanbe",
    "Asia/Famagusta",
    "Asia/Gaza",
    "Asia/Hebron",
    "Asia/Ho_Chi_Minh",
    "Asia/Hong_Kong",
    "Asia/Hovd",
    "Asia/Irkutsk",
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kamchatka",
    "Asia/Karachi",
    "Asia/Kathmandu",
    "Asia/Khandyga",
    "Asia/Kolkata",
    "Asia/Krasnoyarsk",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Asia/Kuwait",
    "Asia/Macau",
    "Asia/Magadan",
    "Asia/Makassar",
    "Asia/Manila",
    "Asia/Muscat",
    "Asia/Nicosia",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Oral",
    "Asia/Phnom_Penh",
    "Asia/Pontianak",
    "Asia/Pyongyang",
    "Asia/Qatar",
    "Asia/Qostanay",
    "Asia/Qyzylorda",
    "Asia/Riyadh",
    "Asia/Sakhalin",
    "Asia/Samarkand",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Srednekolymsk",
    "Asia/Taipei",
    "Asia/Tashkent",
    "Asia/Tbilisi",
    "Asia/Tehran",
    "Asia/Thimphu",
    "Asia/Tokyo",
    "Asia/Tomsk",
    "Asia/Ulaanbaatar",
    "Asia/Urumqi",
    "Asia/Ust-Nera",
    "Asia/Vientiane",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yangon",
    "Asia/Yekaterinburg",
    "Asia/Yerevan",
    "Atlantic/Azores",
    "Atlantic/Bermuda",
    "Atlantic/Canary",
    "Atlantic/Cape_Verde",
    "Atlantic/Faroe",
    "Atlantic/Madeira",
    "Atlantic/Reykjavik",
    "Atlantic/South_Georgia",
    "Atlantic/St_Helena",
    "Atlantic/Stanley",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
    "Indian/Antananarivo",
    "Indian/Chagos",
    "Indian/Christmas",
    "Indian/Cocos",
    "Indian/Comoro",
    "Indian/Kerguelen",
    "Indian/Mahe",
    "Indian/Maldives",
    "Indian/Mauritius",
    "Indian/Mayotte",
    "Indian/Reunion",
    "Pacific/Apia",
    "Pacific/Auckland",
    "Pacific/Bougainville",
    "Pacific/Chatham",
    "Pacific/Chuuk",
    "Pacific/Easter",
    "Pacific/Efate",
    "Pacific/Fakaofo",
    "Pacific/Fiji",
    "Pacific/Funafuti",
    "Pacific/Galapagos",
    "Pacific/Gambier",
    "Pacific/Guadalcanal",
    "Pacific/Guam",
    "Pacific/Honolulu",
    "Pacific/Kanton",
    "Pacific/Kiritimati",
    "Pacific/Kosrae",
    "Pacific/Kwajalein",
    "Pacific/Majuro",
    "Pacific/Marquesas",
    "Pacific/Midway",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Norfolk",
    "Pacific/Noumea",
    "Pacific/Pago_Pago",
    "Pacific/Palau",
    "Pacific/Pitcairn",
    "Pacific/Pohnpei",
    "Pacific/Port_Moresby",
    "Pacific/Rarotonga",
    "Pacific/Saipan",
    "Pacific/Tahiti",
    "Pacific/Tarawa",
    "Pacific/Tongatapu",
    "Pacific/Wake",
    "Pacific/Wallis",
    "UTC",
  ],
  locales: ["", "English (United States)"],
  hour_format: ["12", "24"],
  themes: [
    "redmond",
    "blitzer",
    "cupertino",
    "flick",
    "le-frog",
    "lightness",
    "redmond",
    "smoothness",
  ],
  models: {
    access_controls: [
      {
        text: "\\module\\access_controls\\model\\acos_aros",
        value: "\\module\\access_controls\\model\\acos_aros",
      },
      {
        text: "\\module\\access_controls\\model\\acos",
        value: "\\module\\access_controls\\model\\acos",
      },
      {
        text: "\\module\\access_controls\\model\\aros",
        value: "\\module\\access_controls\\model\\aros",
      },
      {
        text: "\\module\\access_controls\\model\\config_departments",
        value: "\\module\\access_controls\\model\\config_departments",
      },
      {
        text: "\\module\\access_controls\\model\\configurations",
        value: "\\module\\access_controls\\model\\configurations",
      },
      {
        text: "\\module\\access_controls\\model\\design_acl",
        value: "\\module\\access_controls\\model\\design_acl",
      },
      {
        text: "\\module\\access_controls\\model\\dynamic_acl",
        value: "\\module\\access_controls\\model\\dynamic_acl",
      },
      {
        text: "\\module\\access_controls\\model\\group_contain_groups",
        value: "\\module\\access_controls\\model\\group_contain_groups",
      },
      {
        text: "\\module\\access_controls\\model\\groups",
        value: "\\module\\access_controls\\model\\groups",
      },
      {
        text: "\\module\\access_controls\\model\\groups_users",
        value: "\\module\\access_controls\\model\\groups_users",
      },
      {
        text: "\\module\\access_controls\\model\\iam",
        value: "\\module\\access_controls\\model\\iam",
      },
      {
        text: "\\module\\access_controls\\model\\roles",
        value: "\\module\\access_controls\\model\\roles",
      },
      {
        text: "\\module\\access_controls\\model\\roles_users",
        value: "\\module\\access_controls\\model\\roles_users",
      },
      {
        text: "\\module\\access_controls\\model\\users",
        value: "\\module\\access_controls\\model\\users",
      },
    ],
    accounting: [
      {
        text: "\\module\\accounting\\model\\accounting_bank_account_types",
        value: "\\module\\accounting\\model\\accounting_bank_account_types",
      },
      {
        text: "\\module\\accounting\\model\\accounts",
        value: "\\module\\accounting\\model\\accounts",
      },
      {
        text: "\\module\\accounting\\model\\account_types",
        value: "\\module\\accounting\\model\\account_types",
      },
      {
        text: "\\module\\accounting\\model\\analytic_accounts",
        value: "\\module\\accounting\\model\\analytic_accounts",
      },
      {
        text: "\\module\\accounting\\model\\analytic_journals",
        value: "\\module\\accounting\\model\\analytic_journals",
      },
      {
        text: "\\module\\accounting\\model\\asset_categories",
        value: "\\module\\accounting\\model\\asset_categories",
      },
      {
        text: "\\module\\accounting\\model\\asset_depreciation_lines",
        value: "\\module\\accounting\\model\\asset_depreciation_lines",
      },
      {
        text: "\\module\\accounting\\model\\assets",
        value: "\\module\\accounting\\model\\assets",
      },
      {
        text: "\\module\\accounting\\model\\balance_sheet",
        value: "\\module\\accounting\\model\\balance_sheet",
      },
      {
        text: "\\module\\accounting\\model\\bank_accounts",
        value: "\\module\\accounting\\model\\bank_accounts",
      },
      {
        text: "\\module\\accounting\\model\\bank_account_types",
        value: "\\module\\accounting\\model\\bank_account_types",
      },
      {
        text: "\\module\\accounting\\model\\bank_reconciliation",
        value: "\\module\\accounting\\model\\bank_reconciliation",
      },
      {
        text: "\\module\\accounting\\model\\bank_statement_lines",
        value: "\\module\\accounting\\model\\bank_statement_lines",
      },
      {
        text: "\\module\\accounting\\model\\bank_statements",
        value: "\\module\\accounting\\model\\bank_statements",
      },
      {
        text: "\\module\\accounting\\model\\bill_account_lines",
        value: "\\module\\accounting\\model\\bill_account_lines",
      },
      {
        text: "\\module\\accounting\\model\\bill_lines",
        value: "\\module\\accounting\\model\\bill_lines",
      },
      {
        text: "\\module\\accounting\\model\\bill_payment_lines",
        value: "\\module\\accounting\\model\\bill_payment_lines",
      },
      {
        text: "\\module\\accounting\\model\\bill_payments",
        value: "\\module\\accounting\\model\\bill_payments",
      },
      {
        text: "\\module\\accounting\\model\\bills",
        value: "\\module\\accounting\\model\\bills",
      },
      {
        text: "\\module\\accounting\\model\\cheque_item_lines",
        value: "\\module\\accounting\\model\\cheque_item_lines",
      },
      {
        text: "\\module\\accounting\\model\\cheque_lines",
        value: "\\module\\accounting\\model\\cheque_lines",
      },
      {
        text: "\\module\\accounting\\model\\cheques",
        value: "\\module\\accounting\\model\\cheques",
      },
      {
        text: "\\module\\accounting\\model\\company_accounting",
        value: "\\module\\accounting\\model\\company_accounting",
      },
      {
        text: "\\module\\accounting\\model\\company_fiscal_years",
        value: "\\module\\accounting\\model\\company_fiscal_years",
      },
      {
        text: "\\module\\accounting\\model\\config_invoice_status",
        value: "\\module\\accounting\\model\\config_invoice_status",
      },
      {
        text: "\\module\\accounting\\model\\config_tax_types",
        value: "\\module\\accounting\\model\\config_tax_types",
      },
      {
        text: "\\module\\accounting\\model\\credit_memo_lines",
        value: "\\module\\accounting\\model\\credit_memo_lines",
      },
      {
        text: "\\module\\accounting\\model\\credit_memos",
        value: "\\module\\accounting\\model\\credit_memos",
      },
      {
        text: "\\module\\accounting\\model\\deposit_lines",
        value: "\\module\\accounting\\model\\deposit_lines",
      },
      {
        text: "\\module\\accounting\\model\\deposits",
        value: "\\module\\accounting\\model\\deposits",
      },
      {
        text: "\\module\\accounting\\model\\depreciation_lines",
        value: "\\module\\accounting\\model\\depreciation_lines",
      },
      {
        text: "\\module\\accounting\\model\\expense_account_lines",
        value: "\\module\\accounting\\model\\expense_account_lines",
      },
      {
        text: "\\module\\accounting\\model\\expenses_lines",
        value: "\\module\\accounting\\model\\expenses_lines",
      },
      {
        text: "\\module\\accounting\\model\\expenses",
        value: "\\module\\accounting\\model\\expenses",
      },
      {
        text: "\\module\\accounting\\model\\fiscal_positions",
        value: "\\module\\accounting\\model\\fiscal_positions",
      },
      {
        text: "\\module\\accounting\\model\\fiscal_years",
        value: "\\module\\accounting\\model\\fiscal_years",
      },
      {
        text: "\\module\\accounting\\model\\internal_types",
        value: "\\module\\accounting\\model\\internal_types",
      },
      {
        text: "\\module\\accounting\\model\\invoice_lines",
        value: "\\module\\accounting\\model\\invoice_lines",
      },
      {
        text: "\\module\\accounting\\model\\invoice_line_taxes",
        value: "\\module\\accounting\\model\\invoice_line_taxes",
      },
      {
        text: "\\module\\accounting\\model\\invoices_payments",
        value: "\\module\\accounting\\model\\invoices_payments",
      },
      {
        text: "\\module\\accounting\\model\\invoices",
        value: "\\module\\accounting\\model\\invoices",
      },
      {
        text: "\\module\\accounting\\model\\invoice_taxes",
        value: "\\module\\accounting\\model\\invoice_taxes",
      },
      {
        text: "\\module\\accounting\\model\\invoice_types",
        value: "\\module\\accounting\\model\\invoice_types",
      },
      {
        text: "\\module\\accounting\\model\\journal_allowed_accounts",
        value: "\\module\\accounting\\model\\journal_allowed_accounts",
      },
      {
        text: "\\module\\accounting\\model\\journal_allowed_account_types",
        value: "\\module\\accounting\\model\\journal_allowed_account_types",
      },
      {
        text: "\\module\\accounting\\model\\journal_entries",
        value: "\\module\\accounting\\model\\journal_entries",
      },
      {
        text: "\\module\\accounting\\model\\journal_entry_lines",
        value: "\\module\\accounting\\model\\journal_entry_lines",
      },
      {
        text: "\\module\\accounting\\model\\journal_entry_line_status",
        value: "\\module\\accounting\\model\\journal_entry_line_status",
      },
      {
        text: "\\module\\accounting\\model\\journal_entry_status",
        value: "\\module\\accounting\\model\\journal_entry_status",
      },
      {
        text: "\\module\\accounting\\model\\journals",
        value: "\\module\\accounting\\model\\journals",
      },
      {
        text: "\\module\\accounting\\model\\journal_types",
        value: "\\module\\accounting\\model\\journal_types",
      },
      {
        text: "\\module\\accounting\\model\\line_taxes",
        value: "\\module\\accounting\\model\\line_taxes",
      },
      {
        text: "\\module\\accounting\\model\\line_taxes_summary",
        value: "\\module\\accounting\\model\\line_taxes_summary",
      },
      {
        text: "\\module\\accounting\\model\\move_lines",
        value: "\\module\\accounting\\model\\move_lines",
      },
      {
        text: "\\module\\accounting\\model\\moves",
        value: "\\module\\accounting\\model\\moves",
      },
      {
        text: "\\module\\accounting\\model\\outgoing_currencies_rates",
        value: "\\module\\accounting\\model\\outgoing_currencies_rates",
      },
      {
        text: "\\module\\accounting\\model\\partner_accounts",
        value: "\\module\\accounting\\model\\partner_accounts",
      },
      {
        text: "\\module\\accounting\\model\\payments",
        value: "\\module\\accounting\\model\\payments",
      },
      {
        text: "\\module\\accounting\\model\\payment_term_lines",
        value: "\\module\\accounting\\model\\payment_term_lines",
      },
      {
        text: "\\module\\accounting\\model\\payment_terms",
        value: "\\module\\accounting\\model\\payment_terms",
      },
      {
        text: "\\module\\accounting\\model\\periods",
        value: "\\module\\accounting\\model\\periods",
      },
      {
        text: "\\module\\accounting\\model\\product_accounts",
        value: "\\module\\accounting\\model\\product_accounts",
      },
      {
        text: "\\module\\accounting\\model\\refund_lines",
        value: "\\module\\accounting\\model\\refund_lines",
      },
      {
        text: "\\module\\accounting\\model\\refund_receipt_lines",
        value: "\\module\\accounting\\model\\refund_receipt_lines",
      },
      {
        text: "\\module\\accounting\\model\\refund_receipts",
        value: "\\module\\accounting\\model\\refund_receipts",
      },
      {
        text: "\\module\\accounting\\model\\refunds",
        value: "\\module\\accounting\\model\\refunds",
      },
      {
        text: "\\module\\accounting\\model\\sales_receipts_lines",
        value: "\\module\\accounting\\model\\sales_receipts_lines",
      },
      {
        text: "\\module\\accounting\\model\\sales_receipts",
        value: "\\module\\accounting\\model\\sales_receipts",
      },
      {
        text: "\\module\\accounting\\model\\tax_application",
        value: "\\module\\accounting\\model\\tax_application",
      },
      {
        text: "\\module\\accounting\\model\\tax_code",
        value: "\\module\\accounting\\model\\tax_code",
      },
      {
        text: "\\module\\accounting\\model\\taxes",
        value: "\\module\\accounting\\model\\taxes",
      },
      {
        text: "\\module\\accounting\\model\\tax_types",
        value: "\\module\\accounting\\model\\tax_types",
      },
      {
        text: "\\module\\accounting\\model\\transfer",
        value: "\\module\\accounting\\model\\transfer",
      },
      {
        text: "\\module\\accounting\\model\\voucher_lines",
        value: "\\module\\accounting\\model\\voucher_lines",
      },
      {
        text: "\\module\\accounting\\model\\vouchers",
        value: "\\module\\accounting\\model\\vouchers",
      },
    ],
    activities: [
      {
        text: "\\module\\activities\\model\\configurations",
        value: "\\module\\activities\\model\\configurations",
      },
      {
        text: "\\module\\activities\\model\\email_addresses",
        value: "\\module\\activities\\model\\email_addresses",
      },
      {
        text: "\\module\\activities\\model\\email_recipients",
        value: "\\module\\activities\\model\\email_recipients",
      },
      {
        text: "\\module\\activities\\model\\emails",
        value: "\\module\\activities\\model\\emails",
      },
    ],
    admin_module_installer: [
      {
        text: "\\module\\admin_module_installer\\model\\modules",
        value: "\\module\\admin_module_installer\\model\\modules",
      },
    ],
    administration: [
      {
        text: "\\module\\administration\\model\\global_configurations",
        value: "\\module\\administration\\model\\global_configurations",
      },
      {
        text: "\\module\\administration\\model\\smtp_servers",
        value: "\\module\\administration\\model\\smtp_servers",
      },
      {
        text: "\\module\\administration\\model\\user_licenses",
        value: "\\module\\administration\\model\\user_licenses",
      },
    ],
    analytics: [
      {
        text: "\\module\\analytics\\model\\report_categories",
        value: "\\module\\analytics\\model\\report_categories",
      },
      {
        text: "\\module\\analytics\\model\\reports",
        value: "\\module\\analytics\\model\\reports",
      },
    ],
    appraisal: [
      {
        text: "\\module\\appraisal\\model\\appraisal_plans",
        value: "\\module\\appraisal\\model\\appraisal_plans",
      },
      {
        text: "\\module\\appraisal\\model\\appraisals",
        value: "\\module\\appraisal\\model\\appraisals",
      },
    ],
    attendance: [
      {
        text: "\\module\\attendance\\model\\attendances",
        value: "\\module\\attendance\\model\\attendances",
      },
    ],
    base: [
      {
        text: "\\data_model",
        value: "\\data_model",
      },
      {
        text: "\\design_model",
        value: "\\design_model",
      },
      {
        text: "\\acl_model",
        value: "\\acl_model",
      },
      {
        text: "\\kernel\\model",
        value: "\\kernel\\model",
      },
    ],
    brules: [
      {
        text: "\\module\\brules\\model\\business_rule_runlevels",
        value: "\\module\\brules\\model\\business_rule_runlevels",
      },
      {
        text: "\\module\\brules\\model\\business_rule_run_log",
        value: "\\module\\brules\\model\\business_rule_run_log",
      },
      {
        text: "\\module\\brules\\model\\business_rules",
        value: "\\module\\brules\\model\\business_rules",
      },
    ],
    calendar: [
      {
        text: "\\module\\calendar\\model\\calendar_share_with",
        value: "\\module\\calendar\\model\\calendar_share_with",
      },
      {
        text: "\\module\\calendar\\model\\calendars",
        value: "\\module\\calendar\\model\\calendars",
      },
      {
        text: "\\module\\calendar\\model\\config_task_priorities",
        value: "\\module\\calendar\\model\\config_task_priorities",
      },
      {
        text: "\\module\\calendar\\model\\config_task_status",
        value: "\\module\\calendar\\model\\config_task_status",
      },
      {
        text: "\\module\\calendar\\model\\configurations",
        value: "\\module\\calendar\\model\\configurations",
      },
      {
        text: "\\module\\calendar\\model\\event_guests",
        value: "\\module\\calendar\\model\\event_guests",
      },
      {
        text: "\\module\\calendar\\model\\event_related_to",
        value: "\\module\\calendar\\model\\event_related_to",
      },
      {
        text: "\\module\\calendar\\model\\event_reminders",
        value: "\\module\\calendar\\model\\event_reminders",
      },
      {
        text: "\\module\\calendar\\model\\events",
        value: "\\module\\calendar\\model\\events",
      },
      {
        text: "\\module\\calendar\\model\\task_related_to",
        value: "\\module\\calendar\\model\\task_related_to",
      },
      {
        text: "\\module\\calendar\\model\\tasks",
        value: "\\module\\calendar\\model\\tasks",
      },
    ],
    calls: [
      {
        text: "\\module\\calls\\model\\logs",
        value: "\\module\\calls\\model\\logs",
      },
      {
        text: "\\module\\calls\\model\\type",
        value: "\\module\\calls\\model\\type",
      },
    ],
    campaigns: [
      {
        text: "\\module\\campaigns\\model\\campaign_actions",
        value: "\\module\\campaigns\\model\\campaign_actions",
      },
      {
        text: "\\module\\campaigns\\model\\campaign_chaining",
        value: "\\module\\campaigns\\model\\campaign_chaining",
      },
      {
        text: "\\module\\campaigns\\model\\campaign_lists",
        value: "\\module\\campaigns\\model\\campaign_lists",
      },
      {
        text: "\\module\\campaigns\\model\\campaign_listviews",
        value: "\\module\\campaigns\\model\\campaign_listviews",
      },
      {
        text: "\\module\\campaigns\\model\\campaign_log",
        value: "\\module\\campaigns\\model\\campaign_log",
      },
      {
        text: "\\module\\campaigns\\model\\campaign_log_summary",
        value: "\\module\\campaigns\\model\\campaign_log_summary",
      },
      {
        text: "\\module\\campaigns\\model\\campaign_run_log",
        value: "\\module\\campaigns\\model\\campaign_run_log",
      },
      {
        text: "\\module\\campaigns\\model\\campaign_run_status",
        value: "\\module\\campaigns\\model\\campaign_run_status",
      },
      {
        text: "\\module\\campaigns\\model\\campaigns",
        value: "\\module\\campaigns\\model\\campaigns",
      },
      {
        text: "\\module\\campaigns\\model\\campaign_tags",
        value: "\\module\\campaigns\\model\\campaign_tags",
      },
      {
        text: "\\module\\campaigns\\model\\campaign_test_recipients",
        value: "\\module\\campaigns\\model\\campaign_test_recipients",
      },
      {
        text: "\\module\\campaigns\\model\\config_campaigns_types",
        value: "\\module\\campaigns\\model\\config_campaigns_types",
      },
      {
        text: "\\module\\campaigns\\model\\config_campaign_types",
        value: "\\module\\campaigns\\model\\config_campaign_types",
      },
      {
        text: "\\module\\campaigns\\model\\configurations",
        value: "\\module\\campaigns\\model\\configurations",
      },
      {
        text: "\\module\\campaigns\\model\\csv_recipients",
        value: "\\module\\campaigns\\model\\csv_recipients",
      },
      {
        text: "\\module\\campaigns\\model\\list_records",
        value: "\\module\\campaigns\\model\\list_records",
      },
      {
        text: "\\module\\campaigns\\model\\lists",
        value: "\\module\\campaigns\\model\\lists",
      },
      {
        text: "\\module\\campaigns\\model\\templates",
        value: "\\module\\campaigns\\model\\templates",
      },
    ],
    cases: [
      {
        text: "\\module\\cases\\model\\activities",
        value: "\\module\\cases\\model\\activities",
      },
      {
        text: "\\module\\cases\\model\\cases",
        value: "\\module\\cases\\model\\cases",
      },
      {
        text: "\\module\\cases\\model\\config_priorities",
        value: "\\module\\cases\\model\\config_priorities",
      },
      {
        text: "\\module\\cases\\model\\config_status",
        value: "\\module\\cases\\model\\config_status",
      },
    ],
    contract_management: [
      {
        text: "\\module\\contract_management\\model\\contract_signees",
        value: "\\module\\contract_management\\model\\contract_signees",
      },
      {
        text: "\\module\\contract_management\\model\\contracts",
        value: "\\module\\contract_management\\model\\contracts",
      },
      {
        text: "\\module\\contract_management\\model\\contract_statuses",
        value: "\\module\\contract_management\\model\\contract_statuses",
      },
      {
        text: "\\module\\contract_management\\model\\contract_templates",
        value: "\\module\\contract_management\\model\\contract_templates",
      },
      {
        text: "\\module\\contract_management\\model\\contract_types",
        value: "\\module\\contract_management\\model\\contract_types",
      },
    ],
    contracts: [
      {
        text: "\\module\\contracts\\model\\config_contract_types",
        value: "\\module\\contracts\\model\\config_contract_types",
      },
      {
        text: "\\module\\contracts\\model\\configurations",
        value: "\\module\\contracts\\model\\configurations",
      },
      {
        text: "\\module\\contracts\\model\\contract_data",
        value: "\\module\\contracts\\model\\contract_data",
      },
      {
        text: "\\module\\contracts\\model\\contracts",
        value: "\\module\\contracts\\model\\contracts",
      },
      {
        text: "\\module\\contracts\\model\\templates",
        value: "\\module\\contracts\\model\\templates",
      },
    ],
    core: [
      {
        text: "\\module\\core\\model\\configurations",
        value: "\\module\\core\\model\\configurations",
      },
      {
        text: "\\module\\core\\model\\confirmation",
        value: "\\module\\core\\model\\confirmation",
      },
      {
        text: "\\module\\core\\model\\db_session_handler",
        value: "\\module\\core\\model\\db_session_handler",
      },
      {
        text: "\\module\\core\\model\\email_templates",
        value: "\\module\\core\\model\\email_templates",
      },
      {
        text: "\\module\\core\\model\\form_defaults",
        value: "\\module\\core\\model\\form_defaults",
      },
      {
        text: "\\module\\core\\model\\listview_categories",
        value: "\\module\\core\\model\\listview_categories",
      },
      {
        text: "\\module\\core\\model\\listviews",
        value: "\\module\\core\\model\\listviews",
      },
      {
        text: "\\module\\core\\model\\printviews",
        value: "\\module\\core\\model\\printviews",
      },
      {
        text: "\\module\\core\\model\\recyclebin",
        value: "\\module\\core\\model\\recyclebin",
      },
      {
        text: "\\module\\core\\model\\revision_log",
        value: "\\module\\core\\model\\revision_log",
      },
      {
        text: "\\module\\core\\model\\settings",
        value: "\\module\\core\\model\\settings",
      },
    ],
    crm: [
      {
        text: "\\module\\crm\\model\\companies",
        value: "\\module\\crm\\model\\companies",
      },
      {
        text: "\\module\\crm\\model\\config_account_types",
        value: "\\module\\crm\\model\\config_account_types",
      },
      {
        text: "\\module\\crm\\model\\config_departments",
        value: "\\module\\crm\\model\\config_departments",
      },
      {
        text: "\\module\\crm\\model\\config_industries",
        value: "\\module\\crm\\model\\config_industries",
      },
      {
        text: "\\module\\crm\\model\\config_lead_sources",
        value: "\\module\\crm\\model\\config_lead_sources",
      },
      {
        text: "\\module\\crm\\model\\config_lead_status",
        value: "\\module\\crm\\model\\config_lead_status",
      },
      {
        text: "\\module\\crm\\model\\config_lead_type",
        value: "\\module\\crm\\model\\config_lead_type",
      },
      {
        text: "\\module\\crm\\model\\config_opportunity_types",
        value: "\\module\\crm\\model\\config_opportunity_types",
      },
      {
        text: "\\module\\crm\\model\\config_people_types",
        value: "\\module\\crm\\model\\config_people_types",
      },
      {
        text: "\\module\\crm\\model\\config_priorities",
        value: "\\module\\crm\\model\\config_priorities",
      },
      {
        text: "\\module\\crm\\model\\config_product_categories",
        value: "\\module\\crm\\model\\config_product_categories",
      },
      {
        text: "\\module\\crm\\model\\config_ratings",
        value: "\\module\\crm\\model\\config_ratings",
      },
      {
        text: "\\module\\crm\\model\\config_sales_stages",
        value: "\\module\\crm\\model\\config_sales_stages",
      },
      {
        text: "\\module\\crm\\model\\configurations",
        value: "\\module\\crm\\model\\configurations",
      },
      {
        text: "\\module\\crm\\model\\leads",
        value: "\\module\\crm\\model\\leads",
      },
      {
        text: "\\module\\crm\\model\\leads_revision_log",
        value: "\\module\\crm\\model\\leads_revision_log",
      },
      {
        text: "\\module\\crm\\model\\opportunities",
        value: "\\module\\crm\\model\\opportunities",
      },
      {
        text: "\\module\\crm\\model\\peoples",
        value: "\\module\\crm\\model\\peoples",
      },
      {
        text: "\\module\\crm\\model\\products",
        value: "\\module\\crm\\model\\products",
      },
    ],
    crm_base: [
      {
        text: "\\module\\crm_base\\model\\addresses",
        value: "\\module\\crm_base\\model\\addresses",
      },
      {
        text: "\\module\\crm_base\\model\\addresses_revision_log",
        value: "\\module\\crm_base\\model\\addresses_revision_log",
      },
      {
        text: "\\module\\crm_base\\model\\config_address_types",
        value: "\\module\\crm_base\\model\\config_address_types",
      },
      {
        text: "\\module\\crm_base\\model\\config_campaigns_types",
        value: "\\module\\crm_base\\model\\config_campaigns_types",
      },
      {
        text: "\\module\\crm_base\\model\\config_campaign_types",
        value: "\\module\\crm_base\\model\\config_campaign_types",
      },
      {
        text: "\\module\\crm_base\\model\\config_email_types",
        value: "\\module\\crm_base\\model\\config_email_types",
      },
      {
        text: "\\module\\crm_base\\model\\config_evaluation_status",
        value: "\\module\\crm_base\\model\\config_evaluation_status",
      },
      {
        text: "\\module\\crm_base\\model\\config_phone_types",
        value: "\\module\\crm_base\\model\\config_phone_types",
      },
      {
        text: "\\module\\crm_base\\model\\configurations",
        value: "\\module\\crm_base\\model\\configurations",
      },
      {
        text: "\\module\\crm_base\\model\\config_week_days",
        value: "\\module\\crm_base\\model\\config_week_days",
      },
      {
        text: "\\module\\crm_base\\model\\email_addresses",
        value: "\\module\\crm_base\\model\\email_addresses",
      },
      {
        text: "\\module\\crm_base\\model\\email_addresses_revision_log",
        value: "\\module\\crm_base\\model\\email_addresses_revision_log",
      },
      {
        text: "\\module\\crm_base\\model\\organizations",
        value: "\\module\\crm_base\\model\\organizations",
      },
      {
        text: "\\module\\crm_base\\model\\phone_numbers",
        value: "\\module\\crm_base\\model\\phone_numbers",
      },
      {
        text: "\\module\\crm_base\\model\\phone_numbers_revision_log",
        value: "\\module\\crm_base\\model\\phone_numbers_revision_log",
      },
      {
        text: "\\module\\crm_base\\model\\scheduler",
        value: "\\module\\crm_base\\model\\scheduler",
      },
      {
        text: "\\module\\crm_base\\model\\sequences",
        value: "\\module\\crm_base\\model\\sequences",
      },
    ],
    dashboards: [
      {
        text: "\\module\\dashboards\\model\\panels",
        value: "\\module\\dashboards\\model\\panels",
      },
      {
        text: "\\module\\dashboards\\model\\widget_permissions",
        value: "\\module\\dashboards\\model\\widget_permissions",
      },
      {
        text: "\\module\\dashboards\\model\\widgets",
        value: "\\module\\dashboards\\model\\widgets",
      },
    ],
    data_management: [
      {
        text: "\\module\\data_management\\model\\configurations",
        value: "\\module\\data_management\\model\\configurations",
      },
      {
        text: "\\module\\data_management\\model\\exports",
        value: "\\module\\data_management\\model\\exports",
      },
      {
        text: "\\module\\data_management\\model\\import_log",
        value: "\\module\\data_management\\model\\import_log",
      },
      {
        text: "\\module\\data_management\\model\\import_run_log",
        value: "\\module\\data_management\\model\\import_run_log",
      },
      {
        text: "\\module\\data_management\\model\\imports",
        value: "\\module\\data_management\\model\\imports",
      },
    ],
    delivery: [
      {
        text: "\\module\\delivery\\model\\config_freight_terms",
        value: "\\module\\delivery\\model\\config_freight_terms",
      },
      {
        text: "\\module\\delivery\\model\\config_incoterms",
        value: "\\module\\delivery\\model\\config_incoterms",
      },
      {
        text: "\\module\\delivery\\model\\shipping_methods",
        value: "\\module\\delivery\\model\\shipping_methods",
      },
    ],
    development: [
      {
        text: "\\module\\development\\model\\locales",
        value: "\\module\\development\\model\\locales",
      },
      {
        text: "\\module\\development\\model\\locale_translations",
        value: "\\module\\development\\model\\locale_translations",
      },
      {
        text: "\\module\\development\\model\\modules",
        value: "\\module\\development\\model\\modules",
      },
      {
        text: "\\module\\development\\model\\table_comments",
        value: "\\module\\development\\model\\table_comments",
      },
    ],
    development_base: [
      {
        text: "\\module\\development_base\\model\\acl_roles",
        value: "\\module\\development_base\\model\\acl_roles",
      },
      {
        text: "\\module\\development_base\\model\\associations",
        value: "\\module\\development_base\\model\\associations",
      },
      {
        text: "\\module\\development_base\\model\\change_log",
        value: "\\module\\development_base\\model\\change_log",
      },
      {
        text: "\\module\\development_base\\model\\configurations",
        value: "\\module\\development_base\\model\\configurations",
      },
      {
        text: "\\module\\development_base\\model\\controller_properties",
        value: "\\module\\development_base\\model\\controller_properties",
      },
      {
        text: "\\module\\development_base\\model\\controllers",
        value: "\\module\\development_base\\model\\controllers",
      },
      {
        text: "\\module\\development_base\\model\\dependencies",
        value: "\\module\\development_base\\model\\dependencies",
      },
      {
        text: "\\module\\development_base\\model\\form_properties",
        value: "\\module\\development_base\\model\\form_properties",
      },
      {
        text: "\\module\\development_base\\model\\forms",
        value: "\\module\\development_base\\model\\forms",
      },
      {
        text: "\\module\\development_base\\model\\locales",
        value: "\\module\\development_base\\model\\locales",
      },
      {
        text: "\\module\\development_base\\model\\locale_translations",
        value: "\\module\\development_base\\model\\locale_translations",
      },
      {
        text: "\\module\\development_base\\model\\menus",
        value: "\\module\\development_base\\model\\menus",
      },
      {
        text: "\\module\\development_base\\model\\model_properties",
        value: "\\module\\development_base\\model\\model_properties",
      },
      {
        text: "\\module\\development_base\\model\\models",
        value: "\\module\\development_base\\model\\models",
      },
      {
        text: "\\module\\development_base\\model\\module_categories",
        value: "\\module\\development_base\\model\\module_categories",
      },
      {
        text: "\\module\\development_base\\model\\module_industries",
        value: "\\module\\development_base\\model\\module_industries",
      },
      {
        text: "\\module\\development_base\\model\\modules",
        value: "\\module\\development_base\\model\\modules",
      },
    ],
    document_management_base: [
      {
        text: "\\module\\document_management_base\\model\\attachment_content",
        value: "\\module\\document_management_base\\model\\attachment_content",
      },
      {
        text: "\\module\\document_management_base\\model\\attachments",
        value: "\\module\\document_management_base\\model\\attachments",
      },
      {
        text: "\\module\\document_management_base\\model\\images",
        value: "\\module\\document_management_base\\model\\images",
      },
    ],
    documentation: [
      {
        text: "\\module\\documentation\\model\\comments",
        value: "\\module\\documentation\\model\\comments",
      },
      {
        text: "\\module\\documentation\\model\\related_topics",
        value: "\\module\\documentation\\model\\related_topics",
      },
      {
        text: "\\module\\documentation\\model\\topics",
        value: "\\module\\documentation\\model\\topics",
      },
    ],
    email: [
      {
        text: "\\module\\email\\model\\configurations",
        value: "\\module\\email\\model\\configurations",
      },
      {
        text: "\\module\\email\\model\\contacts",
        value: "\\module\\email\\model\\contacts",
      },
      {
        text: "\\module\\email\\model\\email_addresses",
        value: "\\module\\email\\model\\email_addresses",
      },
      {
        text: "\\module\\email\\model\\email_recipients",
        value: "\\module\\email\\model\\email_recipients",
      },
      {
        text: "\\module\\email\\model\\emails",
        value: "\\module\\email\\model\\emails",
      },
    ],
    email_intelligence: [
      {
        text: "\\module\\email_intelligence\\model\\mail_accounts",
        value: "\\module\\email_intelligence\\model\\mail_accounts",
      },
      {
        text: "\\module\\email_intelligence\\model\\mail_scanner_log",
        value: "\\module\\email_intelligence\\model\\mail_scanner_log",
      },
      {
        text: "\\module\\email_intelligence\\model\\message_log",
        value: "\\module\\email_intelligence\\model\\message_log",
      },
    ],
    faq: [
      {
        text: "\\module\\faq\\model\\configurations",
        value: "\\module\\faq\\model\\configurations",
      },
      {
        text: "\\module\\faq\\model\\faq_answers",
        value: "\\module\\faq\\model\\faq_answers",
      },
      {
        text: "\\module\\faq\\model\\faq_categories",
        value: "\\module\\faq\\model\\faq_categories",
      },
      {
        text: "\\module\\faq\\model\\faq",
        value: "\\module\\faq\\model\\faq",
      },
    ],
    flexflow: [
      {
        text: "\\module\\flexflow\\model\\action_approve",
        value: "\\module\\flexflow\\model\\action_approve",
      },
      {
        text: "\\module\\flexflow\\model\\action_cancel",
        value: "\\module\\flexflow\\model\\action_cancel",
      },
      {
        text: "\\module\\flexflow\\model\\action_deny",
        value: "\\module\\flexflow\\model\\action_deny",
      },
      {
        text: "\\module\\flexflow\\model\\action_draft",
        value: "\\module\\flexflow\\model\\action_draft",
      },
      {
        text: "\\module\\flexflow\\model\\action_log",
        value: "\\module\\flexflow\\model\\action_log",
      },
      {
        text: "\\module\\flexflow\\model\\action_mark_as_close",
        value: "\\module\\flexflow\\model\\action_mark_as_close",
      },
      {
        text: "\\module\\flexflow\\model\\action_mark_as_void",
        value: "\\module\\flexflow\\model\\action_mark_as_void",
      },
      {
        text: "\\module\\flexflow\\model\\action_nmi_back",
        value: "\\module\\flexflow\\model\\action_nmi_back",
      },
      {
        text: "\\module\\flexflow\\model\\action_nmi",
        value: "\\module\\flexflow\\model\\action_nmi",
      },
      {
        text: "\\module\\flexflow\\model\\action_redirect",
        value: "\\module\\flexflow\\model\\action_redirect",
      },
      {
        text: "\\module\\flexflow\\model\\actions_notifications",
        value: "\\module\\flexflow\\model\\actions_notifications",
      },
      {
        text: "\\module\\flexflow\\model\\actions",
        value: "\\module\\flexflow\\model\\actions",
      },
      {
        text: "\\module\\flexflow\\model\\action_submit",
        value: "\\module\\flexflow\\model\\action_submit",
      },
      {
        text: "\\module\\flexflow\\model\\actors",
        value: "\\module\\flexflow\\model\\actors",
      },
      {
        text: "\\module\\flexflow\\model\\actor_stage",
        value: "\\module\\flexflow\\model\\actor_stage",
      },
      {
        text: "\\module\\flexflow\\model\\authorized_actions",
        value: "\\module\\flexflow\\model\\authorized_actions",
      },
      {
        text: "\\module\\flexflow\\model\\docs",
        value: "\\module\\flexflow\\model\\docs",
      },
      {
        text: "\\module\\flexflow\\model\\move_to_stages",
        value: "\\module\\flexflow\\model\\move_to_stages",
      },
      {
        text: "\\module\\flexflow\\model\\notifications",
        value: "\\module\\flexflow\\model\\notifications",
      },
      {
        text: "\\module\\flexflow\\model\\notifications_stages",
        value: "\\module\\flexflow\\model\\notifications_stages",
      },
      {
        text: "\\module\\flexflow\\model\\notified_users_log",
        value: "\\module\\flexflow\\model\\notified_users_log",
      },
      {
        text: "\\module\\flexflow\\model\\possible_actions",
        value: "\\module\\flexflow\\model\\possible_actions",
      },
      {
        text: "\\module\\flexflow\\model\\rel_log",
        value: "\\module\\flexflow\\model\\rel_log",
      },
      {
        text: "\\module\\flexflow\\model\\schemas",
        value: "\\module\\flexflow\\model\\schemas",
      },
      {
        text: "\\module\\flexflow\\model\\stage_log",
        value: "\\module\\flexflow\\model\\stage_log",
      },
      {
        text: "\\module\\flexflow\\model\\stages",
        value: "\\module\\flexflow\\model\\stages",
      },
      {
        text: "\\module\\flexflow\\model\\types",
        value: "\\module\\flexflow\\model\\types",
      },
      {
        text: "\\module\\flexflow\\model\\xvars",
        value: "\\module\\flexflow\\model\\xvars",
      },
    ],
    flexflow_test: [
      {
        text: "\\module\\flexflow_test\\model\\configurations",
        value: "\\module\\flexflow_test\\model\\configurations",
      },
      {
        text: "\\module\\flexflow_test\\model\\patterns",
        value: "\\module\\flexflow_test\\model\\patterns",
      },
    ],
    help: [
      {
        text: "\\module\\help\\model\\configurations",
        value: "\\module\\help\\model\\configurations",
      },
      {
        text: "\\module\\help\\model\\note_categories",
        value: "\\module\\help\\model\\note_categories",
      },
      {
        text: "\\module\\help\\model\\notes",
        value: "\\module\\help\\model\\notes",
      },
    ],
    hr: [
      {
        text: "\\module\\hr\\model\\contracts",
        value: "\\module\\hr\\model\\contracts",
      },
      {
        text: "\\module\\hr\\model\\contract_type",
        value: "\\module\\hr\\model\\contract_type",
      },
      {
        text: "\\module\\hr\\model\\employees",
        value: "\\module\\hr\\model\\employees",
      },
      {
        text: "\\module\\hr\\model\\holiday_schedule_lines",
        value: "\\module\\hr\\model\\holiday_schedule_lines",
      },
      {
        text: "\\module\\hr\\model\\holiday_schedule",
        value: "\\module\\hr\\model\\holiday_schedule",
      },
      {
        text: "\\module\\hr\\model\\invoiceable",
        value: "\\module\\hr\\model\\invoiceable",
      },
      {
        text: "\\module\\hr\\model\\job_positions",
        value: "\\module\\hr\\model\\job_positions",
      },
      {
        text: "\\module\\hr\\model\\projects",
        value: "\\module\\hr\\model\\projects",
      },
      {
        text: "\\module\\hr\\model\\timesheet_lines",
        value: "\\module\\hr\\model\\timesheet_lines",
      },
      {
        text: "\\module\\hr\\model\\timesheets",
        value: "\\module\\hr\\model\\timesheets",
      },
      {
        text: "\\module\\hr\\model\\working_schedule",
        value: "\\module\\hr\\model\\working_schedule",
      },
      {
        text: "\\module\\hr\\model\\work_schedule_lines",
        value: "\\module\\hr\\model\\work_schedule_lines",
      },
    ],
    icons: [
      {
        text: "\\module\\icons\\model\\icon_classes",
        value: "\\module\\icons\\model\\icon_classes",
      },
    ],
    installation: [
      {
        text: "\\module\\installation\\model\\app",
        value: "\\module\\installation\\model\\app",
      },
    ],
    job_queue: [
      {
        text: "\\module\\job_queue\\model\\job_instances",
        value: "\\module\\job_queue\\model\\job_instances",
      },
      {
        text: "\\module\\job_queue\\model\\job_run_details",
        value: "\\module\\job_queue\\model\\job_run_details",
      },
      {
        text: "\\module\\job_queue\\model\\job_run_status",
        value: "\\module\\job_queue\\model\\job_run_status",
      },
      {
        text: "\\module\\job_queue\\model\\jobs",
        value: "\\module\\job_queue\\model\\jobs",
      },
    ],
    kpi: [
      {
        text: "\\module\\kpi\\model\\goal_types",
        value: "\\module\\kpi\\model\\goal_types",
      },
      {
        text: "\\module\\kpi\\model\\group_goals",
        value: "\\module\\kpi\\model\\group_goals",
      },
      {
        text: "\\module\\kpi\\model\\notification_related_to",
        value: "\\module\\kpi\\model\\notification_related_to",
      },
      {
        text: "\\module\\kpi\\model\\notifications",
        value: "\\module\\kpi\\model\\notifications",
      },
    ],
    leave_management: [
      {
        text: "\\module\\leave_management\\model\\leave_allocations",
        value: "\\module\\leave_management\\model\\leave_allocations",
      },
      {
        text: "\\module\\leave_management\\model\\leaves",
        value: "\\module\\leave_management\\model\\leaves",
      },
      {
        text: "\\module\\leave_management\\model\\leave_type",
        value: "\\module\\leave_management\\model\\leave_type",
      },
    ],
    license_management: [
      {
        text: "\\module\\license_management\\model\\license_domains",
        value: "\\module\\license_management\\model\\license_domains",
      },
      {
        text: "\\module\\license_management\\model\\license_ips",
        value: "\\module\\license_management\\model\\license_ips",
      },
      {
        text: "\\module\\license_management\\model\\licenses",
        value: "\\module\\license_management\\model\\licenses",
      },
    ],
    logging: [
      {
        text: "\\module\\logging\\model\\action_history",
        value: "\\module\\logging\\model\\action_history",
      },
      {
        text: "\\module\\logging\\model\\debug_levels",
        value: "\\module\\logging\\model\\debug_levels",
      },
      {
        text: "\\module\\logging\\model\\event_log",
        value: "\\module\\logging\\model\\event_log",
      },
      {
        text: "\\module\\logging\\model\\login_history",
        value: "\\module\\logging\\model\\login_history",
      },
      {
        text: "\\module\\logging\\model\\sql_log",
        value: "\\module\\logging\\model\\sql_log",
      },
    ],
    manufacturing: [
      {
        text: "\\module\\manufacturing\\model\\bill_of_materials",
        value: "\\module\\manufacturing\\model\\bill_of_materials",
      },
      {
        text: "\\module\\manufacturing\\model\\bill_of_material_types",
        value: "\\module\\manufacturing\\model\\bill_of_material_types",
      },
      {
        text: "\\module\\manufacturing\\model\\bom_components",
        value: "\\module\\manufacturing\\model\\bom_components",
      },
      {
        text: "\\module\\manufacturing\\model\\finished_products",
        value: "\\module\\manufacturing\\model\\finished_products",
      },
      {
        text: "\\module\\manufacturing\\model\\manufacturing_orders",
        value: "\\module\\manufacturing\\model\\manufacturing_orders",
      },
      {
        text: "\\module\\manufacturing\\model\\products_consumed",
        value: "\\module\\manufacturing\\model\\products_consumed",
      },
      {
        text: "\\module\\manufacturing\\model\\products_to_consume",
        value: "\\module\\manufacturing\\model\\products_to_consume",
      },
      {
        text: "\\module\\manufacturing\\model\\routing_orders",
        value: "\\module\\manufacturing\\model\\routing_orders",
      },
      {
        text: "\\module\\manufacturing\\model\\work_center_operations",
        value: "\\module\\manufacturing\\model\\work_center_operations",
      },
      {
        text: "\\module\\manufacturing\\model\\work_centers",
        value: "\\module\\manufacturing\\model\\work_centers",
      },
      {
        text: "\\module\\manufacturing\\model\\work_orders",
        value: "\\module\\manufacturing\\model\\work_orders",
      },
    ],
    masters: [
      {
        text: "\\module\\masters\\model\\config_salutations",
        value: "\\module\\masters\\model\\config_salutations",
      },
      {
        text: "\\module\\masters\\model\\config_unit_of_measures",
        value: "\\module\\masters\\model\\config_unit_of_measures",
      },
      {
        text: "\\module\\masters\\model\\configurations",
        value: "\\module\\masters\\model\\configurations",
      },
      {
        text: "\\module\\masters\\model\\countries",
        value: "\\module\\masters\\model\\countries",
      },
      {
        text: "\\module\\masters\\model\\currencies",
        value: "\\module\\masters\\model\\currencies",
      },
      {
        text: "\\module\\masters\\model\\languages",
        value: "\\module\\masters\\model\\languages",
      },
      {
        text: "\\module\\masters\\model\\regions",
        value: "\\module\\masters\\model\\regions",
      },
      {
        text: "\\module\\masters\\model\\sic",
        value: "\\module\\masters\\model\\sic",
      },
      {
        text: "\\module\\masters\\model\\states",
        value: "\\module\\masters\\model\\states",
      },
      {
        text: "\\module\\masters\\model\\unit_of_measure_categories",
        value: "\\module\\masters\\model\\unit_of_measure_categories",
      },
    ],
    metadata: [
      {
        text: "\\module\\metadata\\model\\database_tables",
        value: "\\module\\metadata\\model\\database_tables",
      },
      {
        text: "\\module\\metadata\\model\\schema",
        value: "\\module\\metadata\\model\\schema",
      },
    ],
    payroll: [
      {
        text: "\\module\\payroll\\model\\payslips",
        value: "\\module\\payroll\\model\\payslips",
      },
      {
        text: "\\module\\payroll\\model\\salary_computation_logs",
        value: "\\module\\payroll\\model\\salary_computation_logs",
      },
      {
        text: "\\module\\payroll\\model\\salary_rule_categories",
        value: "\\module\\payroll\\model\\salary_rule_categories",
      },
      {
        text: "\\module\\payroll\\model\\salary_rules",
        value: "\\module\\payroll\\model\\salary_rules",
      },
      {
        text: "\\module\\payroll\\model\\salary_structures",
        value: "\\module\\payroll\\model\\salary_structures",
      },
      {
        text: "\\module\\payroll\\model\\worked_days",
        value: "\\module\\payroll\\model\\worked_days",
      },
    ],
    product: [
      {
        text: "\\module\\product\\model\\config_price_list_items",
        value: "\\module\\product\\model\\config_price_list_items",
      },
      {
        text: "\\module\\product\\model\\config_pricelist_versions",
        value: "\\module\\product\\model\\config_pricelist_versions",
      },
      {
        text: "\\module\\product\\model\\config_uom",
        value: "\\module\\product\\model\\config_uom",
      },
      {
        text: "\\module\\product\\model\\pricelists",
        value: "\\module\\product\\model\\pricelists",
      },
      {
        text: "\\module\\product\\model\\product_categories",
        value: "\\module\\product\\model\\product_categories",
      },
      {
        text: "\\module\\product\\model\\products",
        value: "\\module\\product\\model\\products",
      },
      {
        text: "\\module\\product\\model\\product_suppliers",
        value: "\\module\\product\\model\\product_suppliers",
      },
      {
        text: "\\module\\product\\model\\product_supplier_types",
        value: "\\module\\product\\model\\product_supplier_types",
      },
      {
        text: "\\module\\product\\model\\supplier_types",
        value: "\\module\\product\\model\\supplier_types",
      },
      {
        text: "\\module\\product\\model\\uom_categories",
        value: "\\module\\product\\model\\uom_categories",
      },
      {
        text: "\\module\\product\\model\\warehouse_locations",
        value: "\\module\\product\\model\\warehouse_locations",
      },
    ],
    projects: [
      {
        text: "\\module\\projects\\model\\comments",
        value: "\\module\\projects\\model\\comments",
      },
      {
        text: "\\module\\projects\\model\\projects",
        value: "\\module\\projects\\model\\projects",
      },
      {
        text: "\\module\\projects\\model\\resources",
        value: "\\module\\projects\\model\\resources",
      },
      {
        text: "\\module\\projects\\model\\resourcetype",
        value: "\\module\\projects\\model\\resourcetype",
      },
      {
        text: "\\module\\projects\\model\\severities",
        value: "\\module\\projects\\model\\severities",
      },
      {
        text: "\\module\\projects\\model\\sprints",
        value: "\\module\\projects\\model\\sprints",
      },
      {
        text: "\\module\\projects\\model\\status",
        value: "\\module\\projects\\model\\status",
      },
      {
        text: "\\module\\projects\\model\\tickets",
        value: "\\module\\projects\\model\\tickets",
      },
    ],
    purchase: [
      {
        text: "\\module\\purchase\\model\\config_purchase_order_lines",
        value: "\\module\\purchase\\model\\config_purchase_order_lines",
      },
      {
        text: "\\module\\purchase\\model\\config_purchase_quote_lines",
        value: "\\module\\purchase\\model\\config_purchase_quote_lines",
      },
      {
        text: "\\module\\purchase\\model\\purchase_orders",
        value: "\\module\\purchase\\model\\purchase_orders",
      },
      {
        text: "\\module\\purchase\\model\\purchase_quotes",
        value: "\\module\\purchase\\model\\purchase_quotes",
      },
    ],
    recruitment: [
      {
        text: "\\module\\recruitment\\model\\applications",
        value: "\\module\\recruitment\\model\\applications",
      },
      {
        text: "\\module\\recruitment\\model\\config_degrees",
        value: "\\module\\recruitment\\model\\config_degrees",
      },
      {
        text: "\\module\\recruitment\\model\\config_sources",
        value: "\\module\\recruitment\\model\\config_sources",
      },
      {
        text: "\\module\\recruitment\\model\\jobs",
        value: "\\module\\recruitment\\model\\jobs",
      },
    ],
    sales: [
      {
        text: "\\module\\sales\\model\\config_payment_method",
        value: "\\module\\sales\\model\\config_payment_method",
      },
      {
        text: "\\module\\sales\\model\\config_payment_terms",
        value: "\\module\\sales\\model\\config_payment_terms",
      },
      {
        text: "\\module\\sales\\model\\config_quote_status",
        value: "\\module\\sales\\model\\config_quote_status",
      },
      {
        text: "\\module\\sales\\model\\config_sales_order_status",
        value: "\\module\\sales\\model\\config_sales_order_status",
      },
      {
        text: "\\module\\sales\\model\\quote_lines",
        value: "\\module\\sales\\model\\quote_lines",
      },
      {
        text: "\\module\\sales\\model\\quotes",
        value: "\\module\\sales\\model\\quotes",
      },
      {
        text: "\\module\\sales\\model\\sale_order_lines",
        value: "\\module\\sales\\model\\sale_order_lines",
      },
      {
        text: "\\module\\sales\\model\\sale_orders",
        value: "\\module\\sales\\model\\sale_orders",
      },
      {
        text: "\\module\\sales\\model\\sale_shop",
        value: "\\module\\sales\\model\\sale_shop",
      },
      {
        text: "\\module\\sales\\model\\sales_team",
        value: "\\module\\sales\\model\\sales_team",
      },
    ],
    stock: [
      {
        text: "\\module\\stock\\model\\config_delivery_order_status",
        value: "\\module\\stock\\model\\config_delivery_order_status",
      },
      {
        text: "\\module\\stock\\model\\config_inbound_shipment_lines",
        value: "\\module\\stock\\model\\config_inbound_shipment_lines",
      },
      {
        text: "\\module\\stock\\model\\delivery_order_lines",
        value: "\\module\\stock\\model\\delivery_order_lines",
      },
      {
        text: "\\module\\stock\\model\\delivery_orders",
        value: "\\module\\stock\\model\\delivery_orders",
      },
      {
        text: "\\module\\stock\\model\\inbound_shipments",
        value: "\\module\\stock\\model\\inbound_shipments",
      },
      {
        text: "\\module\\stock\\model\\inventory_lines",
        value: "\\module\\stock\\model\\inventory_lines",
      },
      {
        text: "\\module\\stock\\model\\inventory",
        value: "\\module\\stock\\model\\inventory",
      },
      {
        text: "\\module\\stock\\model\\location",
        value: "\\module\\stock\\model\\location",
      },
      {
        text: "\\module\\stock\\model\\location_product_quantity",
        value: "\\module\\stock\\model\\location_product_quantity",
      },
      {
        text: "\\module\\stock\\model\\stock_moves",
        value: "\\module\\stock\\model\\stock_moves",
      },
      {
        text: "\\module\\stock\\model\\transfer_orders",
        value: "\\module\\stock\\model\\transfer_orders",
      },
      {
        text: "\\module\\stock\\model\\warehouse",
        value: "\\module\\stock\\model\\warehouse",
      },
    ],
    tag_cloud: [
      {
        text: "\\module\\tag_cloud\\model\\tag_related_to",
        value: "\\module\\tag_cloud\\model\\tag_related_to",
      },
      {
        text: "\\module\\tag_cloud\\model\\tags",
        value: "\\module\\tag_cloud\\model\\tags",
      },
    ],
    tags: [
      {
        text: "\\module\\tags\\model\\tag_cloud",
        value: "\\module\\tags\\model\\tag_cloud",
      },
      {
        text: "\\module\\tags\\model\\tags",
        value: "\\module\\tags\\model\\tags",
      },
    ],
    web_forms: [
      {
        text: "\\module\\web_forms\\model\\record_log",
        value: "\\module\\web_forms\\model\\record_log",
      },
      {
        text: "\\module\\web_forms\\model\\web_forms",
        value: "\\module\\web_forms\\model\\web_forms",
      },
    ],
  },
  value_popupoptions: {
    CRM: [
      {
        text: "Attachments",
        value: "attachments",
        href: "document_management_base/attachments/index",
        model: "attachments",
      },
      {
        text: "Companies",
        value: "companies",
        href: "crm/companies/index",
        model: "companies",
      },
      {
        text: "Dashboard",
        value: "dashboard",
        href: "crm/dashboard/index",
        model: "dashboard",
      },
      {
        text: "Leads",
        value: "leads",
        href: "crm/leads/index",
        model: "leads",
      },
      {
        text: "Opportunities",
        value: "opportunities",
        href: "crm/opportunities/index",
        model: "opportunities",
      },
      {
        text: "Peoples",
        value: "peoples",
        href: "crm/peoples/index",
        model: "peoples",
      },
    ],
    "CRM [Configuration]": [
      {
        text: "Config Departments",
        value: "config_departments",
        href: "crm/config_departments/index",
        model: "config_departments",
      },
      {
        text: "Config Industries",
        value: "config_industries",
        href: "crm/config_industries/index",
        model: "config_industries",
      },
      {
        text: "Config Lead Sources",
        value: "config_lead_sources",
        href: "crm/config_lead_sources/index",
        model: "config_lead_sources",
      },
      {
        text: "Config People Types",
        value: "config_people_types",
        href: "crm/config_people_types/index",
        model: "config_people_types",
      },
      {
        text: "Config Ratings",
        value: "config_ratings",
        href: "crm/config_ratings/index",
        model: "config_ratings",
      },
      {
        text: "Config Salutations",
        value: "config_salutations",
        href: "masters/config_salutations/index",
        model: "config_salutations",
      },
      {
        text: "Configurations",
        value: "configurations",
        href: "crm/configurations/index",
        model: "configurations",
      },
      {
        text: "Countries",
        value: "countries",
        href: "masters/countries/index",
        model: "countries",
      },
      {
        text: "Currencies",
        value: "currencies",
        href: "masters/currencies/index",
        model: "currencies",
      },
    ],
    "e-Marketing": [
      {
        text: "Campaign Log",
        value: "campaign_log",
        href: "campaigns/campaign_log/index",
        model: "campaign_log",
      },
      {
        text: "Campaign Log Summary",
        value: "campaign_log_summary",
        href: "campaigns/campaign_log_summary/index",
        model: "campaign_log_summary",
      },
      {
        text: "Campaigns",
        value: "campaigns",
        href: "campaigns/campaigns/index",
        model: "campaigns",
      },
    ],
    "e-Marketing [Configuration]": [
      {
        text: "Campaigns Acl Cache",
        value: "campaigns_acl_cache",
        href: "campaigns/campaigns_acl_cache/index",
        model: "campaigns_acl_cache",
      },
      {
        text: "Campaigns Group Acl Cache",
        value: "campaigns_group_acl_cache",
        href: "campaigns/campaigns_group_acl_cache/index",
        model: "campaigns_group_acl_cache",
      },
      {
        text: "Configurations",
        value: "configurations",
        href: "campaigns/configurations/index",
        model: "configurations",
      },
    ],
    "e-Mail": [
      {
        text: "Contacts",
        value: "contacts",
        href: "email/contacts/index",
        model: "contacts",
      },
      {
        text: "Outbound Emails",
        value: "emails",
        href: "email/emails/index",
        model: "emails",
      },
    ],
    "e-Mail [Configuration]": [
      {
        text: "Configurations",
        value: "configurations",
        href: "email/configurations/index",
        model: "configurations",
      },
      {
        text: "Email Recipients",
        value: "email_recipients",
        href: "email/email_recipients/index",
        model: "email_recipients",
      },
    ],
    Calendar: [
      {
        text: "Events",
        value: "events",
        href: "calendar/events/index",
        model: "events",
      },
      {
        text: "Tasks",
        value: "tasks",
        href: "calendar/tasks/index",
        model: "tasks",
      },
    ],
    "Calendar [Configuration]": [
      {
        text: "Config Task Priorities",
        value: "config_task_priorities",
        href: "calendar/config_task_priorities/index",
        model: "config_task_priorities",
      },
      {
        text: "Config Task Status",
        value: "config_task_status",
        href: "calendar/config_task_status/index",
        model: "config_task_status",
      },
      {
        text: "Configurations",
        value: "configurations",
        href: "calendar/configurations/index",
        model: "configurations",
      },
    ],
    Support: [
      {
        text: "Call Records",
        value: "logs",
        href: "calls/logs/index",
        model: "logs",
      },
      {
        text: "Cases",
        value: "cases",
        href: "cases/cases/index",
        model: "cases",
      },
      {
        text: "Email Intelligence",
        value: "mail_accounts",
        href: "email_intelligence/mail_accounts/index",
        model: "mail_accounts",
      },
    ],
    Sales: [
      {
        text: "Pricelists",
        value: "pricelists",
        href: "product/pricelists/index",
        model: "pricelists",
      },
      {
        text: "Products",
        value: "products",
        href: "product/products/index",
        model: "products",
      },
      {
        text: "Quotes",
        value: "quotes",
        href: "sales/quotes/index",
        model: "quotes",
      },
      {
        text: "Sale Orders",
        value: "sale_orders",
        href: "sales/sale_orders/index",
        model: "sale_orders",
      },
      {
        text: "Sale Shop",
        value: "sale_shop",
        href: "sales/sale_shop/index",
        model: "sale_shop",
      },
    ],
    "Business Intelligence": [
      {
        text: "Dashboards",
        value: "widgets",
        href: "dashboards/widgets/index",
        model: "widgets",
      },
      {
        text: "KPI Goals",
        value: "group_goals",
        href: "kpi/group_goals/index",
        model: "group_goals",
      },
      {
        text: "KPI Notifications",
        value: "notifications",
        href: "kpi/notifications/index",
        model: "notifications",
      },
      {
        text: "Reports",
        value: "reports",
        href: "analytics/reports/index",
        model: "reports",
      },
    ],
    Accounting: [
      {
        text: "Account Types",
        value: "account_types",
        href: "accounting/account_types/index",
        model: "account_types",
      },
      {
        text: "Accounts",
        value: "accounts",
        href: "accounting/accounts/index",
        model: "accounts",
      },
      {
        text: "Analytic Accounts",
        value: "analytic_accounts",
        href: "accounting/analytic_accounts/index",
        model: "analytic_accounts",
      },
      {
        text: "Analytic Journals",
        value: "analytic_journals",
        href: "accounting/analytic_journals/index",
        model: "analytic_journals",
      },
      {
        text: "Asset Categories",
        value: "asset_categories",
        href: "accounting/asset_categories/index",
        model: "asset_categories",
      },
      {
        text: "Asset Depreciation Lines",
        value: "asset_depreciation_lines",
        href: "accounting/asset_depreciation_lines/index",
        model: "asset_depreciation_lines",
      },
      {
        text: "Assets",
        value: "assets",
        href: "accounting/assets/index",
        model: "assets",
      },
      {
        text: "Bill Account Lines",
        value: "bill_account_lines",
        href: "accounting/bill_account_lines/index",
        model: "bill_account_lines",
      },
      {
        text: "Bill Payment Lines",
        value: "bill_payment_lines",
        href: "accounting/bill_payment_lines/index",
        model: "bill_payment_lines",
      },
      {
        text: "Checks",
        value: "checks",
        href: "accounting/checks/index",
        model: "checks",
      },
      {
        text: "Cheque Item Lines",
        value: "cheque_item_lines",
        href: "accounting/cheque_item_lines/index",
        model: "cheque_item_lines",
      },
      {
        text: "Company Accounting",
        value: "company_accounting",
        href: "accounting/company_accounting/index",
        model: "company_accounting",
      },
      {
        text: "Company Fiscal Years",
        value: "company_fiscal_years",
        href: "accounting/company_fiscal_years/index",
        model: "company_fiscal_years",
      },
      {
        text: "Credit Memo Lines",
        value: "credit_memo_lines",
        href: "accounting/credit_memo_lines/index",
        model: "credit_memo_lines",
      },
      {
        text: "Deposit",
        value: "deposit",
        href: "accounting/deposit/index",
        model: "deposit",
      },
      {
        text: "Deposits",
        value: "deposits",
        href: "accounting/deposits/index",
        model: "deposits",
      },
      {
        text: "Depreciation Lines",
        value: "depreciation_lines",
        href: "accounting/depreciation_lines/index",
        model: "depreciation_lines",
      },
      {
        text: "Expense Account Lines",
        value: "expense_account_lines",
        href: "accounting/expense_account_lines/index",
        model: "expense_account_lines",
      },
      {
        text: "Fiscal Positions",
        value: "fiscal_positions",
        href: "accounting/fiscal_positions/index",
        model: "fiscal_positions",
      },
      {
        text: "Internal Types",
        value: "internal_types",
        href: "accounting/internal_types/index",
        model: "internal_types",
      },
      {
        text: "Invoice Line Taxes",
        value: "invoice_line_taxes",
        href: "accounting/invoice_line_taxes/index",
        model: "invoice_line_taxes",
      },
      {
        text: "Invoice Lines",
        value: "invoice_lines",
        href: "accounting/invoice_lines/index",
        model: "invoice_lines",
      },
      {
        text: "Invoice Taxes",
        value: "invoice_taxes",
        href: "accounting/invoice_taxes/index",
        model: "invoice_taxes",
      },
      {
        text: "Invoice Types",
        value: "invoice_types",
        href: "accounting/invoice_types/index",
        model: "invoice_types",
      },
      {
        text: "Invoices Payments",
        value: "invoices_payments",
        href: "accounting/invoices_payments/index",
        model: "invoices_payments",
      },
      {
        text: "Journal Allowed Account Types",
        value: "journal_allowed_account_types",
        href: "accounting/journal_allowed_account_types/index",
        model: "journal_allowed_account_types",
      },
      {
        text: "Journal Allowed Accounts",
        value: "journal_allowed_accounts",
        href: "accounting/journal_allowed_accounts/index",
        model: "journal_allowed_accounts",
      },
      {
        text: "Journal Entry Line Status",
        value: "journal_entry_line_status",
        href: "accounting/journal_entry_line_status/index",
        model: "journal_entry_line_status",
      },
      {
        text: "Journal Entry Lines",
        value: "journal_entry_lines",
        href: "accounting/journal_entry_lines/index",
        model: "journal_entry_lines",
      },
      {
        text: "Journal Entry Status",
        value: "journal_entry_status",
        href: "accounting/journal_entry_status/index",
        model: "journal_entry_status",
      },
      {
        text: "Journal Types",
        value: "journal_types",
        href: "accounting/journal_types/index",
        model: "journal_types",
      },
      {
        text: "Journals",
        value: "journals",
        href: "accounting/journals/index",
        model: "journals",
      },
      {
        text: "Line Taxes",
        value: "line_taxes",
        href: "accounting/line_taxes/index",
        model: "line_taxes",
      },
      {
        text: "Line Taxes Summary",
        value: "line_taxes_summary",
        href: "accounting/line_taxes_summary/index",
        model: "line_taxes_summary",
      },
      {
        text: "Move Lines",
        value: "move_lines",
        href: "accounting/move_lines/index",
        model: "move_lines",
      },
      {
        text: "Moves",
        value: "moves",
        href: "accounting/moves/index",
        model: "moves",
      },
      {
        text: "Outgoing Currencies Rates",
        value: "outgoing_currencies_rates",
        href: "accounting/outgoing_currencies_rates/index",
        model: "outgoing_currencies_rates",
      },
      {
        text: "Partner Accounts",
        value: "partner_accounts",
        href: "accounting/partner_accounts/index",
        model: "partner_accounts",
      },
      {
        text: "Payment Term Lines",
        value: "payment_term_lines",
        href: "accounting/payment_term_lines/index",
        model: "payment_term_lines",
      },
      {
        text: "Payment Terms",
        value: "payment_terms",
        href: "accounting/payment_terms/index",
        model: "payment_terms",
      },
      {
        text: "Payments",
        value: "payments",
        href: "accounting/payments/index",
        model: "payments",
      },
      {
        text: "Product Accounts",
        value: "product_accounts",
        href: "accounting/product_accounts/index",
        model: "product_accounts",
      },
      {
        text: "Products Accounting",
        value: "products_accounting",
        href: "accounting/products_accounting/index",
        model: "products_accounting",
      },
      {
        text: "Refund Lines",
        value: "refund_lines",
        href: "accounting/refund_lines/index",
        model: "refund_lines",
      },
      {
        text: "Refunds",
        value: "refunds",
        href: "accounting/refunds/index",
        model: "refunds",
      },
      {
        text: "Sales Receipts Lines",
        value: "sales_receipts_lines",
        href: "accounting/sales_receipts_lines/index",
        model: "sales_receipts_lines",
      },
      {
        text: "Tax Application",
        value: "tax_application",
        href: "accounting/tax_application/index",
        model: "tax_application",
      },
      {
        text: "Tax Code",
        value: "tax_code",
        href: "accounting/tax_code/index",
        model: "tax_code",
      },
      {
        text: "Tax Types",
        value: "tax_types",
        href: "accounting/tax_types/index",
        model: "tax_types",
      },
      {
        text: "Taxes",
        value: "taxes",
        href: "accounting/taxes/index",
        model: "taxes",
      },
      {
        text: "Voucher Lines",
        value: "voucher_lines",
        href: "accounting/voucher_lines/index",
        model: "voucher_lines",
      },
      {
        text: "Vouchers",
        value: "vouchers",
        href: "accounting/vouchers/index",
        model: "vouchers",
      },
    ],
    "Accounting/Customers": [
      {
        text: "Credit Memos",
        value: "credit_memos",
        href: "accounting/credit_memos/index",
        model: "credit_memos",
      },
      {
        text: "Invoices",
        value: "invoices",
        href: "accounting/invoices/index",
        model: "invoices",
      },
      {
        text: "Receive Payment ",
        value: "payments",
        href: "accounting/payments/index",
        model: "payments",
      },
      {
        text: "Refund Receipts",
        value: "refund_receipts",
        href: "accounting/refund_receipts/index",
        model: "refund_receipts",
      },
      {
        text: "Sales Receipts",
        value: "sales_receipts",
        href: "accounting/sales_receipts/index",
        model: "sales_receipts",
      },
    ],
    "Accounting/Suppliers": [
      {
        text: "Bill Payments",
        value: "bill_payments",
        href: "accounting/bill_payments/index",
        model: "bill_payments",
      },
      {
        text: "Bills",
        value: "bills",
        href: "accounting/bills/index",
        model: "bills",
      },
      {
        text: "Cheques",
        value: "cheques",
        href: "accounting/cheques/index",
        model: "cheques",
      },
      {
        text: "Expenses",
        value: "expenses",
        href: "accounting/expenses/index",
        model: "expenses",
      },
    ],
    "Accounting/Others": [
      {
        text: "Bank Deposits",
        value: "deposits",
        href: "accounting/deposits/index",
        model: "deposits",
      },
      {
        text: "Bank Reconciliation",
        value: "bank_reconciliation",
        href: "accounting/bank_reconciliation/index",
        model: "bank_reconciliation",
      },
      {
        text: "Bank Statement Lines",
        value: "bank_statement_lines",
        href: "accounting/bank_statement_lines/index",
        model: "bank_statement_lines",
      },
      {
        text: "Bank Statements",
        value: "bank_statements",
        href: "accounting/bank_statements/index",
        model: "bank_statements",
      },
      {
        text: "Journal Entries",
        value: "journal_entries",
        href: "accounting/journal_entries/index",
        model: "journal_entries",
      },
      {
        text: "Transfer",
        value: "transfer",
        href: "accounting/transfer/index",
        model: "transfer",
      },
    ],
    "Accounting/Settings": [
      {
        text: "Balance Sheet",
        value: "balance_sheet",
        href: "accounting/balance_sheet/index",
        model: "balance_sheet",
      },
      {
        text: "Bank Accounts",
        value: "bank_accounts",
        href: "accounting/bank_accounts/index",
        model: "bank_accounts",
      },
      {
        text: "Chart Of Accounts",
        value: "accounts",
        href: "accounting/accounts/index",
        model: "accounts",
      },
      {
        text: "Fiscal Years",
        value: "fiscal_years",
        href: "accounting/fiscal_years/index",
        model: "fiscal_years",
      },
      {
        text: "Periods",
        value: "periods",
        href: "accounting/periods/index",
        model: "periods",
      },
    ],
    Manufacturing: [
      {
        text: "Bill Of Materials",
        value: "bill_of_materials",
        href: "manufacturing/bill_of_materials/index",
        model: "bill_of_materials",
      },
      {
        text: "Manufacturing Orders",
        value: "manufacturing_orders",
        href: "manufacturing/manufacturing_orders/index",
        model: "manufacturing_orders",
      },
      {
        text: "Routing Orders",
        value: "routing_orders",
        href: "manufacturing/routing_orders/index",
        model: "routing_orders",
      },
      {
        text: "Work Centers",
        value: "work_centers",
        href: "manufacturing/work_centers/index",
        model: "work_centers",
      },
      {
        text: "Work Orders",
        value: "work_orders",
        href: "manufacturing/work_orders/index",
        model: "work_orders",
      },
    ],
    "": [
      {
        text: "Contracts",
        value: "contracts",
        href: "contracts/contracts/index",
        model: "contracts",
      },
    ],
    "Development base": [
      {
        text: "Associations",
        value: "associations",
        href: "development_base/associations/index",
        model: "associations",
      },
      {
        text: "Locales",
        value: "locales",
        href: "development_base/locales/index",
        model: "locales",
      },
    ],
    Developer: [
      {
        text: "File mgmt.",
        value: "files",
        href: "development/files/index",
        model: "files",
      },
      {
        text: "Images",
        value: "images",
        href: "document_management_base/images/index",
        model: "images",
      },
      {
        text: "Staging meta-data",
        value: "table_comments",
        href: "development/table_comments/index",
        model: "table_comments",
      },
      {
        text: "Translations",
        value: "locale_translations",
        href: "development/locale_translations/index",
        model: "locale_translations",
      },
      {
        text: "Views",
        value: "listviews",
        href: "core/listviews/index",
        model: "listviews",
      },
    ],
    "Developer/MVC": [
      {
        text: "Controllers",
        value: "controllers",
        href: "development_base/controllers/index",
        model: "controllers",
      },
      {
        text: "Forms",
        value: "forms",
        href: "development_base/forms/index",
        model: "forms",
      },
      {
        text: "Listviews",
        value: "listviews",
        href: "core/listviews/index",
        model: "listviews",
      },
      {
        text: "Models",
        value: "models",
        href: "development_base/models/index",
        model: "models",
      },
      {
        text: "Print views",
        value: "printviews",
        href: "core/printviews/index",
        model: "printviews",
      },
    ],
    "Developer/Config": [
      {
        text: "Biz. Rules",
        value: "business_rules",
        href: "brules/business_rules/index",
        model: "business_rules",
      },
      {
        text: "Locales",
        value: "locales",
        href: "development/locales/index",
        model: "locales",
      },
      {
        text: "Workflow",
        value: "schemas",
        href: "flexflow/schemas/index",
        model: "schemas",
      },
    ],
    "Developer/Reference": [
      {
        text: "Icon Classes",
        value: "icon_classes",
        href: "icons/icon_classes/index",
        model: "icon_classes",
      },
      {
        text: "Workflow Sample Patterns",
        value: "patterns",
        href: "flexflow_test/patterns/index",
        model: "patterns",
      },
    ],
    "Settings/Access-Controls": [
      {
        text: "Groups",
        value: "groups",
        href: "access_controls/groups/index",
        model: "groups",
      },
      {
        text: "Menus",
        value: "menus",
        href: "development_base/menus/index",
        model: "menus",
      },
      {
        text: "Roles",
        value: "roles",
        href: "access_controls/roles/index",
        model: "roles",
      },
      {
        text: "Temp. ACL",
        value: "dynamic_acl",
        href: "access_controls/dynamic_acl/index",
        model: "dynamic_acl",
      },
      {
        text: "Users",
        value: "users",
        href: "access_controls/users/index",
        model: "users",
      },
    ],
    "Settings/Access-Controls [Configuration]": [
      {
        text: "Configurations",
        value: "configurations",
        href: "access_controls/configurations/index",
        model: "configurations",
      },
      {
        text: "Iam",
        value: "iam",
        href: "access_controls/iam/index",
        model: "iam",
      },
    ],
    "Settings/Monitoring": [
      {
        text: "Business rule log",
        value: "business_rule_run_log",
        href: "brules/business_rule_run_log/index",
        model: "business_rule_run_log",
      },
      {
        text: "Campaign Run Log",
        value: "campaign_run_log",
        href: "campaigns/campaign_run_log/index",
        model: "campaign_run_log",
      },
      {
        text: "Email Intl - Mail Scanner Log",
        value: "mail_scanner_log",
        href: "email_intelligence/mail_scanner_log/index",
        model: "mail_scanner_log",
      },
      {
        text: "Email Intl - Message Log",
        value: "message_log",
        href: "email_intelligence/message_log/index",
        model: "message_log",
      },
      {
        text: "Event log",
        value: "event_log",
        href: "logging/event_log/index",
        model: "event_log",
      },
      {
        text: "Login History",
        value: "login_history",
        href: "logging/login_history/index",
        model: "login_history",
      },
      {
        text: "Revision log",
        value: "revision_log",
        href: "core/revision_log/index",
        model: "revision_log",
      },
      {
        text: "Workflow log",
        value: "docs",
        href: "flexflow/docs/index",
        model: "docs",
      },
    ],
    "Settings/Configuration": [
      {
        text: "App license",
        value: "app",
        href: "installation/app/index",
        model: "app",
      },
      {
        text: "Configurations",
        value: "configurations",
        href: "crm_base/configurations/index",
        model: "configurations",
      },
      {
        text: "Contract Templates",
        value: "contract_templates",
        href: "contract_management/contract_templates/index",
        model: "contract_templates",
      },
      {
        text: "Countries",
        value: "countries",
        href: "masters/countries/index",
        model: "countries",
      },
      {
        text: "Currencies",
        value: "currencies",
        href: "masters/currencies/index",
        model: "currencies",
      },
      {
        text: "Email Templates",
        value: "email_templates",
        href: "core/email_templates/index",
        model: "email_templates",
      },
      {
        text: "Form Defaults",
        value: "form_defaults",
        href: "core/form_defaults/index",
        model: "form_defaults",
      },
      {
        text: "Generate License",
        value: "licenses",
        href: "license_management/licenses/index",
        model: "licenses",
      },
      {
        text: "Global configurations",
        value: "global_configurations",
        href: "administration/global_configurations/index",
        model: "global_configurations",
      },
      {
        text: "License_domains",
        value: "license_domains",
        href: "license_management/license_domains/index",
        model: "license_domains",
      },
      {
        text: "License_ips",
        value: "license_ips",
        href: "license_management/license_ips/index",
        model: "license_ips",
      },
      {
        text: "Modules",
        value: "modules",
        href: "development_base/modules/index",
        model: "modules",
      },
      {
        text: "Organizations",
        value: "organizations",
        href: "crm_base/organizations/index",
        model: "organizations",
      },
      {
        text: "Record Log",
        value: "record_log",
        href: "web_forms/record_log/index",
        model: "record_log",
      },
      {
        text: "Schedule jobs",
        value: "jobs",
        href: "job_queue/jobs/index",
        model: "jobs",
      },
      {
        text: "Sequences",
        value: "sequences",
        href: "crm_base/sequences/index",
        model: "sequences",
      },
      {
        text: "User Licenses",
        value: "user_licenses",
        href: "administration/user_licenses/index",
        model: "user_licenses",
      },
      {
        text: "Web Forms",
        value: "web_forms",
        href: "web_forms/web_forms/index",
        model: "web_forms",
      },
    ],
    Settings: [
      {
        text: "Data Imports",
        value: "imports",
        href: "data_management/imports/index",
        model: "imports",
      },
      {
        text: "Documentation",
        value: "topics",
        href: "documentation/topics/index",
        model: "topics",
      },
      {
        text: "Faq",
        value: "faq",
        href: "faq/faq/index",
        model: "faq",
      },
      {
        text: "Faq Answers",
        value: "faq_answers",
        href: "faq/faq_answers/index",
        model: "faq_answers",
      },
    ],
    "Crm base": [
      {
        text: "Addresses",
        value: "addresses",
        href: "crm_base/addresses/index",
        model: "addresses",
      },
      {
        text: "Email addresses",
        value: "email_addresses",
        href: "crm_base/email_addresses/index",
        model: "email_addresses",
      },
      {
        text: "Phone numbers",
        value: "phone_numbers",
        href: "crm_base/phone_numbers/index",
        model: "phone_numbers",
      },
      {
        text: "Scheduler",
        value: "scheduler",
        href: "crm_base/scheduler/index",
        model: "scheduler",
      },
    ],
    Flexflow: [
      {
        text: "Actions Notifications",
        value: "actions_notifications",
        href: "flexflow/actions_notifications/index",
        model: "actions_notifications",
      },
      {
        text: "Actor Stage",
        value: "actor_stage",
        href: "flexflow/actor_stage/index",
        model: "actor_stage",
      },
      {
        text: "Actors",
        value: "actors",
        href: "flexflow/actors/index",
        model: "actors",
      },
      {
        text: "Authorized Actions",
        value: "authorized_actions",
        href: "flexflow/authorized_actions/index",
        model: "authorized_actions",
      },
      {
        text: "Nodes",
        value: "nodes",
        href: "flexflow/nodes/index",
        model: "nodes",
      },
      {
        text: "Notifications Stages",
        value: "notifications_stages",
        href: "flexflow/notifications_stages/index",
        model: "notifications_stages",
      },
      {
        text: "Notified Users Log",
        value: "notified_users_log",
        href: "flexflow/notified_users_log/index",
        model: "notified_users_log",
      },
      {
        text: "Possible Actions",
        value: "possible_actions",
        href: "flexflow/possible_actions/index",
        model: "possible_actions",
      },
      {
        text: "Rel Log",
        value: "rel_log",
        href: "flexflow/rel_log/index",
        model: "rel_log",
      },
      {
        text: "Rules",
        value: "rules",
        href: "flexflow/rules/index",
        model: "rules",
      },
      {
        text: "Schemas",
        value: "schemas",
        href: "flexflow/schemas/index",
        model: "schemas",
      },
      {
        text: "Stage Log",
        value: "stage_log",
        href: "flexflow/stage_log/index",
        model: "stage_log",
      },
      {
        text: "Stage Type",
        value: "stage_type",
        href: "flexflow/stage_type/index",
        model: "stage_type",
      },
      {
        text: "Types",
        value: "types",
        href: "flexflow/types/index",
        model: "types",
      },
      {
        text: "Xvars",
        value: "xvars",
        href: "flexflow/xvars/index",
        model: "xvars",
      },
    ],
    Core: [
      {
        text: "Revision Log",
        value: "revision_log",
        href: "core/revision_log/index",
        model: "revision_log",
      },
    ],
    Delivery: [
      {
        text: "Shipping Methods",
        value: "shipping_methods",
        href: "delivery/shipping_methods/index",
        model: "shipping_methods",
      },
    ],
    Stock: [
      {
        text: "Delivery Orders",
        value: "delivery_orders",
        href: "stock/delivery_orders/index",
        model: "delivery_orders",
      },
      {
        text: "Inbound Shipments",
        value: "inbound_shipments",
        href: "stock/inbound_shipments/index",
        model: "inbound_shipments",
      },
      {
        text: "Locations",
        value: "location",
        href: "stock/location/index",
        model: "location",
      },
      {
        text: "Physical Inventory",
        value: "inventory",
        href: "stock/inventory/index",
        model: "inventory",
      },
      {
        text: "Stock Moves",
        value: "stock_moves",
        href: "stock/stock_moves/index",
        model: "stock_moves",
      },
      {
        text: "Transfer Orders",
        value: "transfer_orders",
        href: "stock/transfer_orders/index",
        model: "transfer_orders",
      },
      {
        text: "Warehouses",
        value: "warehouse",
        href: "stock/warehouse/index",
        model: "warehouse",
      },
    ],
    Purchases: [
      {
        text: "Purchase Orders",
        value: "purchase_orders",
        href: "purchase/purchase_orders/index",
        model: "purchase_orders",
      },
      {
        text: "Purchase Quotes",
        value: "purchase_quotes",
        href: "purchase/purchase_quotes/index",
        model: "purchase_quotes",
      },
    ],
    Products: [
      {
        text: "Config Price List Items",
        value: "config_price_list_items",
        href: "product/config_price_list_items/index",
        model: "config_price_list_items",
      },
      {
        text: "Config Pricelist Versions",
        value: "config_pricelist_versions",
        href: "product/config_pricelist_versions/index",
        model: "config_pricelist_versions",
      },
      {
        text: "Price List Items",
        value: "price_list_items",
        href: "product/price_list_items/index",
        model: "price_list_items",
      },
      {
        text: "Product Categories",
        value: "product_categories",
        href: "product/product_categories/index",
        model: "product_categories",
      },
      {
        text: "Uom Categories",
        value: "uom_categories",
        href: "product/uom_categories/index",
        model: "uom_categories",
      },
      {
        text: "Warehouse Locations",
        value: "warehouse_locations",
        href: "product/warehouse_locations/index",
        model: "warehouse_locations",
      },
    ],
    "Data Management": [
      {
        text: "Exports",
        value: "exports",
        href: "data_management/exports/index",
        model: "exports",
      },
    ],
    "Tag Cloud": [
      {
        text: "Tag Related To",
        value: "tag_related_to",
        href: "tag_cloud/tag_related_to/index",
        model: "tag_related_to",
      },
      {
        text: "Tags",
        value: "tags",
        href: "tag_cloud/tags/index",
        model: "tags",
      },
    ],
    Product: [
      {
        text: "Product Supplier Types",
        value: "product_supplier_types",
        href: "product/product_supplier_types/index",
        model: "product_supplier_types",
      },
      {
        text: "Supplier Types",
        value: "supplier_types",
        href: "product/supplier_types/index",
        model: "supplier_types",
      },
    ],
    "Human Resource": [
      {
        text: "Appraisals",
        value: "appraisals",
        href: "appraisal/appraisals/index",
        model: "appraisals",
      },
      {
        text: "Attendances",
        value: "attendances",
        href: "attendance/attendances/index",
        model: "attendances",
      },
    ],
    "Human Resource/Leave Management": [
      {
        text: "Leave Allocations",
        value: "leave_allocations",
        href: "leave_management/leave_allocations/index",
        model: "leave_allocations",
      },
      {
        text: "Leaves",
        value: "leaves",
        href: "leave_management/leaves/index",
        model: "leaves",
      },
    ],
    "Human Resource/HR": [
      {
        text: "Contracts",
        value: "contracts",
        href: "hr/contracts/index",
        model: "contracts",
      },
      {
        text: "Employees",
        value: "employees",
        href: "hr/employees/index",
        model: "employees",
      },
      {
        text: "Timesheets",
        value: "timesheets",
        href: "hr/timesheets/index",
        model: "timesheets",
      },
    ],
    "Human Resource/Payroll": [
      {
        text: "Payslips",
        value: "payslips",
        href: "payroll/payslips/index",
        model: "payslips",
      },
      {
        text: "Salary Rules",
        value: "salary_rules",
        href: "payroll/salary_rules/index",
        model: "salary_rules",
      },
      {
        text: "Salary Structures",
        value: "salary_structures",
        href: "payroll/salary_structures/index",
        model: "salary_structures",
      },
    ],
    "Human Resource/Recruitment": [
      {
        text: "Applications",
        value: "applications",
        href: "recruitment/applications/index",
        model: "applications",
      },
      {
        text: "Jobs",
        value: "jobs",
        href: "recruitment/jobs/index",
        model: "jobs",
      },
    ],
    "Contract Management": [
      {
        text: "Contracts",
        value: "contracts",
        href: "contract_management/contracts/index",
        model: "contracts",
      },
    ],
    "Contract Management [Configuration]": [
      {
        text: "Contract Signees",
        value: "contract_signees",
        href: "contract_management/contract_signees/index",
        model: "contract_signees",
      },
      {
        text: "Contract Statuses",
        value: "contract_statuses",
        href: "contract_management/contract_statuses/index",
        model: "contract_statuses",
      },
      {
        text: "Contract Types",
        value: "contract_types",
        href: "contract_management/contract_types/index",
        model: "contract_types",
      },
    ],
    "Core [Config]": [
      {
        text: "Views",
        value: "listviews",
        href: "core/listviews/index",
        model: "listviews",
      },
      {
        text: "Listview Categories",
        value: "listview_categories",
        href: "core/listview_categories/index",
        model: "listview_categories",
      },
    ],
    "Development Base [Config]": [
      {
        text: "Module categories",
        value: "module_categories",
        href: "development_base/module_categories/index",
        model: "module_categories",
      },
      {
        text: "Module industries",
        value: "module_industries",
        href: "development_base/module_industries/index",
        model: "module_industries",
      },
      {
        text: "Acl roles",
        value: "acl_roles",
        href: "development_base/acl_roles/index",
        model: "acl_roles",
      },
      {
        text: "Change log",
        value: "change_log",
        href: "development_base/change_log/index",
        model: "change_log",
      },
      {
        text: "Dependencies",
        value: "dependencies",
        href: "development_base/dependencies/index",
        model: "dependencies",
      },
      {
        text: "Forms",
        value: "forms",
        href: "development_base/forms/index",
        model: "forms",
      },
      {
        text: "Translations",
        value: "locale_translations",
        href: "development/locale_translations/index",
        model: "locale_translations",
      },
    ],
    "Accounting [Config]": [
      {
        text: "Chart of taxes",
        value: "tax_code",
        href: "accounting/tax_code/index",
        model: "tax_code",
      },
      {
        text: "Tax application",
        value: "tax_application",
        href: "accounting/tax_application/index",
        model: "tax_application",
      },
      {
        text: "Tax types",
        value: "tax_types",
        href: "accounting/tax_types/index",
        model: "tax_types",
      },
      {
        text: "Sale Order Lines",
        value: "sale_order_lines",
        href: "sales/sale_order_lines/index",
        model: "sale_order_lines",
      },
      {
        text: "Config Inbound Shipment Lines",
        value: "config_inbound_shipment_lines",
        href: "stock/config_inbound_shipment_lines/index",
        model: "config_inbound_shipment_lines",
      },
      {
        text: "Move lines",
        value: "move_lines",
        href: "accounting/move_lines/index",
        model: "move_lines",
      },
      {
        text: "Voucher lines",
        value: "voucher_lines",
        href: "accounting/voucher_lines/index",
        model: "voucher_lines",
      },
      {
        text: "Asset categories",
        value: "asset_categories",
        href: "accounting/asset_categories/index",
        model: "asset_categories",
      },
      {
        text: "Asset depreciation lines",
        value: "asset_depreciation_lines",
        href: "accounting/asset_depreciation_lines/index",
        model: "asset_depreciation_lines",
      },
      {
        text: "Refund Receipt Lines",
        value: "refund_receipt_lines",
        href: "accounting/refund_receipt_lines/index",
        model: "refund_receipt_lines",
      },
      {
        text: "Invoice lines",
        value: "invoice_lines",
        href: "accounting/invoice_lines/index",
        model: "invoice_lines",
      },
      {
        text: "Fiscal positions",
        value: "fiscal_positions",
        href: "accounting/fiscal_positions/index",
        model: "fiscal_positions",
      },
      {
        text: "Invoice taxes",
        value: "invoice_taxes",
        href: "accounting/invoice_taxes/index",
        model: "invoice_taxes",
      },
      {
        text: "Invoice types",
        value: "invoice_types",
        href: "accounting/invoice_types/index",
        model: "invoice_types",
      },
      {
        text: "Config Invoice Status",
        value: "config_invoice_status",
        href: "accounting/config_invoice_status/index",
        model: "config_invoice_status",
      },
      {
        text: "Payment Terms",
        value: "payment_terms",
        href: "accounting/payment_terms/index",
        model: "payment_terms",
      },
      {
        text: "Cheque Lines",
        value: "cheque_lines",
        href: "accounting/cheque_lines/index",
        model: "cheque_lines",
      },
      {
        text: "Bank statement lines",
        value: "bank_statement_lines",
        href: "accounting/bank_statement_lines/index",
        model: "bank_statement_lines",
      },
      {
        text: "Deposit Lines",
        value: "deposit_lines",
        href: "accounting/deposit_lines/index",
        model: "deposit_lines",
      },
      {
        text: "Bank Accounts Types",
        value: "bank_account_types",
        href: "accounting/bank_account_types/index",
        model: "bank_account_types",
      },
      {
        text: "Fiscal Years",
        value: "fiscal_years",
        href: "accounting/fiscal_years/index",
        model: "fiscal_years",
      },
      {
        text: "Config Tax Types",
        value: "config_tax_types",
        href: "accounting/config_tax_types/index",
        model: "config_tax_types",
      },
      {
        text: "Invoice line taxes",
        value: "invoice_line_taxes",
        href: "accounting/invoice_line_taxes/index",
        model: "invoice_line_taxes",
      },
    ],
    "Masters [Config]": [
      {
        text: "Config Unit Of Measures",
        value: "config_unit_of_measures",
        href: "masters/config_unit_of_measures/index",
        model: "config_unit_of_measures",
      },
      {
        text: "Countries",
        value: "countries",
        href: "masters/countries/index",
        model: "countries",
      },
      {
        text: "Config Salutations",
        value: "config_salutations",
        href: "masters/config_salutations/index",
        model: "config_salutations",
      },
      {
        text: "Languages",
        value: "languages",
        href: "masters/languages/index",
        model: "languages",
      },
      {
        text: "Regions",
        value: "regions",
        href: "masters/regions/index",
        model: "regions",
      },
      {
        text: "Unit Of Measure Categories",
        value: "unit_of_measure_categories",
        href: "masters/unit_of_measure_categories/index",
        model: "unit_of_measure_categories",
      },
      {
        text: "States",
        value: "states",
        href: "masters/states/index",
        model: "states",
      },
      {
        text: "Sic",
        value: "sic",
        href: "masters/sic/index",
        model: "sic",
      },
      {
        text: "Currencies",
        value: "currencies",
        href: "masters/currencies/index",
        model: "currencies",
      },
    ],
    "Crm Base [Config]": [
      {
        text: "Config Address Types",
        value: "config_address_types",
        href: "crm_base/config_address_types/index",
        model: "config_address_types",
      },
      {
        text: "Config Campaign Types",
        value: "config_campaign_types",
        href: "crm_base/config_campaign_types/index",
        model: "config_campaign_types",
      },
      {
        text: "Config Week Days",
        value: "config_week_days",
        href: "crm_base/config_week_days/index",
        model: "config_week_days",
      },
      {
        text: "Config Phone Types",
        value: "config_phone_types",
        href: "crm_base/config_phone_types/index",
        model: "config_phone_types",
      },
      {
        text: "Config Evaluation Status",
        value: "config_evaluation_status",
        href: "crm_base/config_evaluation_status/index",
        model: "config_evaluation_status",
      },
      {
        text: "Config Email Types",
        value: "config_email_types",
        href: "crm_base/config_email_types/index",
        model: "config_email_types",
      },
    ],
    "Contracts [Config]": [
      {
        text: "Config Contract Types",
        value: "config_contract_types",
        href: "contracts/config_contract_types/index",
        model: "config_contract_types",
      },
    ],
    "Crm [Config]": [
      {
        text: "Config Lead Sources",
        value: "config_lead_sources",
        href: "crm/config_lead_sources/index",
        model: "config_lead_sources",
      },
      {
        text: "Config Sales Stages",
        value: "config_sales_stages",
        href: "crm/config_sales_stages/index",
        model: "config_sales_stages",
      },
      {
        text: "Config Ratings",
        value: "config_ratings",
        href: "crm/config_ratings/index",
        model: "config_ratings",
      },
      {
        text: "Config Account Types",
        value: "config_account_types",
        href: "crm/config_account_types/index",
        model: "config_account_types",
      },
      {
        text: "Config Industries",
        value: "config_industries",
        href: "crm/config_industries/index",
        model: "config_industries",
      },
      {
        text: "Config Lead Status",
        value: "config_lead_status",
        href: "crm/config_lead_status/index",
        model: "config_lead_status",
      },
      {
        text: "Config Opportunity Types",
        value: "config_opportunity_types",
        href: "crm/config_opportunity_types/index",
        model: "config_opportunity_types",
      },
      {
        text: "Config Product Categories",
        value: "config_product_categories",
        href: "crm/config_product_categories/index",
        model: "config_product_categories",
      },
      {
        text: "Config Lead Type",
        value: "config_lead_type",
        href: "crm/config_lead_type/index",
        model: "config_lead_type",
      },
      {
        text: "Config Priorities",
        value: "config_priorities",
        href: "crm/config_priorities/index",
        model: "config_priorities",
      },
    ],
    "Product [Config]": [
      {
        text: "Config Pricelist Versions",
        value: "config_pricelist_versions",
        href: "product/config_pricelist_versions/index",
        model: "config_pricelist_versions",
      },
      {
        text: "Config Uom",
        value: "config_uom",
        href: "product/config_uom/index",
        model: "config_uom",
      },
      {
        text: "Config Price List Items",
        value: "config_price_list_items",
        href: "product/config_price_list_items/index",
        model: "config_price_list_items",
      },
      {
        text: "Product Suppliers",
        value: "product_suppliers",
        href: "product/product_suppliers/index",
        model: "product_suppliers",
      },
    ],
    "Manufacturing [Config]": [
      {
        text: "Bill Of Material Types",
        value: "bill_of_material_types",
        href: "manufacturing/bill_of_material_types/index",
        model: "bill_of_material_types",
      },
      {
        text: "Products Consumed",
        value: "products_consumed",
        href: "manufacturing/products_consumed/index",
        model: "products_consumed",
      },
      {
        text: "Products To Consume",
        value: "products_to_consume",
        href: "manufacturing/products_to_consume/index",
        model: "products_to_consume",
      },
      {
        text: "Finished Products",
        value: "finished_products",
        href: "manufacturing/finished_products/index",
        model: "finished_products",
      },
      {
        text: "Work Center Operations",
        value: "work_center_operations",
        href: "manufacturing/work_center_operations/index",
        model: "work_center_operations",
      },
    ],
    "Sales [Config]": [
      {
        text: "Sale Order Lines",
        value: "sale_order_lines",
        href: "sales/sale_order_lines/index",
        model: "sale_order_lines",
      },
      {
        text: "Config Status",
        value: "config_status",
        href: "sales/config_status/index",
        model: "config_status",
      },
      {
        text: "Config Quote Status",
        value: "config_quote_status",
        href: "sales/config_quote_status/index",
        model: "config_quote_status",
      },
      {
        text: "Config Sales Order Status",
        value: "config_sales_order_status",
        href: "sales/config_sales_order_status/index",
        model: "config_sales_order_status",
      },
      {
        text: "Config Payment Terms",
        value: "config_payment_terms",
        href: "sales/config_payment_terms/index",
        model: "config_payment_terms",
      },
      {
        text: "Config Payment Method",
        value: "config_payment_method",
        href: "sales/config_payment_method/index",
        model: "config_payment_method",
      },
      {
        text: "Quotelines",
        value: "quotelines",
        href: "sales/quotelines/index",
        model: "quotelines",
      },
      {
        text: "Sales Team",
        value: "sales_team",
        href: "sales/sales_team/index",
        model: "sales_team",
      },
    ],
    "Access Controls [Config]": [
      {
        text: "Group contain groups",
        value: "group_contain_groups",
        href: "access_controls/group_contain_groups/index",
        model: "group_contain_groups",
      },
      {
        text: "Groups users",
        value: "groups_users",
        href: "access_controls/groups_users/index",
        model: "groups_users",
      },
      {
        text: "Design ACL",
        value: "design_acl",
        href: "access_controls/design_acl/index",
        model: "design_acl",
      },
      {
        text: "Roles users",
        value: "roles_users",
        href: "access_controls/roles_users/index",
        model: "roles_users",
      },
      {
        text: "Config departments",
        value: "config_departments",
        href: "access_controls/config_departments/index",
        model: "config_departments",
      },
      {
        text: "Config Departments",
        value: "config_departments",
        href: "access_controls/config_departments/index",
        model: "config_departments",
      },
    ],
    "Delivery [Config]": [
      {
        text: "Config Frieght Terms",
        value: "config_frieght_terms",
        href: "delivery/config_frieght_terms/index",
        model: "config_frieght_terms",
      },
      {
        text: "Config Incoterms",
        value: "config_incoterms",
        href: "delivery/config_incoterms/index",
        model: "config_incoterms",
      },
    ],
    "Flexflow [Config]": [
      {
        text: "Stages",
        value: "stages",
        href: "flexflow/stages/index",
        model: "stages",
      },
      {
        text: "Actions",
        value: "actions",
        href: "flexflow/actions/index",
        model: "actions",
      },
      {
        text: "Action Log",
        value: "action_log",
        href: "flexflow/action_log/index",
        model: "action_log",
      },
      {
        text: "Move To Stages",
        value: "move_to_stages",
        href: "flexflow/move_to_stages/index",
        model: "move_to_stages",
      },
    ],
    "Brules [Config]": [
      {
        text: "Business rule runlevels",
        value: "business_rule_runlevels",
        href: "brules/business_rule_runlevels/index",
        model: "business_rule_runlevels",
      },
    ],
    "Purchase [Config]": [
      {
        text: "Config Purchase Order Lines",
        value: "config_purchase_order_lines",
        href: "purchase/config_purchase_order_lines/index",
        model: "config_purchase_order_lines",
      },
    ],
    "Stock [Config]": [
      {
        text: "Config Delivery Order Status",
        value: "config_delivery_order_status",
        href: "stock/config_delivery_order_status/index",
        model: "config_delivery_order_status",
      },
      {
        text: "Location Product Quantity",
        value: "location_product_quantity",
        href: "stock/location_product_quantity/index",
        model: "location_product_quantity",
      },
    ],
    "Cases [Config]": [
      {
        text: "Activities",
        value: "activities",
        href: "cases/activities/index",
        model: "activities",
      },
      {
        text: "Config Priorities",
        value: "config_priorities",
        href: "cases/config_priorities/index",
        model: "config_priorities",
      },
      {
        text: "Config Status",
        value: "config_status",
        href: "cases/config_status/index",
        model: "config_status",
      },
    ],
    "Calls [Config]": [
      {
        text: "Type",
        value: "type",
        href: "calls/type/index",
        model: "type",
      },
    ],
    "Calendar [Config]": [
      {
        text: "Config Task Priorities",
        value: "config_task_priorities",
        href: "calendar/config_task_priorities/index",
        model: "config_task_priorities",
      },
      {
        text: "Config Task Status",
        value: "config_task_status",
        href: "calendar/config_task_status/index",
        model: "config_task_status",
      },
    ],
    "Data Management [Config]": [
      {
        text: "Import run log",
        value: "import_run_log",
        href: "data_management/import_run_log/index",
        model: "import_run_log",
      },
      {
        text: "Import log",
        value: "import_log",
        href: "data_management/import_log/index",
        model: "import_log",
      },
    ],
    "Logging [Config]": [
      {
        text: "Debug levels",
        value: "debug_levels",
        href: "logging/debug_levels/index",
        model: "debug_levels",
      },
    ],
    "hr [Config]": [
      {
        text: "Job Positions",
        value: "job_positions",
        href: "hr/job_positions/index",
        model: "job_positions",
      },
      {
        text: "Holiday Schedule",
        value: "holiday_schedule",
        href: "hr/holiday_schedule/index",
        model: "holiday_schedule",
      },
      {
        text: "Projects",
        value: "projects",
        href: "hr/projects/index",
        model: "projects",
      },
      {
        text: "Working Schedule",
        value: "working_schedule",
        href: "hr/working_schedule/index",
        model: "working_schedule",
      },
      {
        text: "Contract Type",
        value: "contract_type",
        href: "hr/contract_type/index",
        model: "contract_type",
      },
      {
        text: "Work Schedule Lines",
        value: "work_schedule_lines",
        href: "hr/work_schedule_lines/index",
        model: "work_schedule_lines",
      },
      {
        text: "Timesheet Lines",
        value: "timesheet_lines",
        href: "hr/timesheet_lines/index",
        model: "timesheet_lines",
      },
      {
        text: "Projects",
        value: "projects",
        href: "hr/projects/index",
        model: "projects",
      },
      {
        text: "Invoiceable",
        value: "invoiceable",
        href: "hr/invoiceable/index",
        model: "invoiceable",
      },
      {
        text: "Holiday Schedule Lines",
        value: "holiday_schedule_lines",
        href: "hr/holiday_schedule_lines/index",
        model: "holiday_schedule_lines",
      },
    ],
    "payroll [Config]": [
      {
        text: "Salary Computation Logs",
        value: "salary_computation_logs",
        href: "payroll/salary_computation_logs/index",
        model: "salary_computation_logs",
      },
      {
        text: "Worked Days",
        value: "worked_days",
        href: "payroll/worked_days/index",
        model: "worked_days",
      },
      {
        text: "Salary Rule Categories",
        value: "salary_rule_categories",
        href: "payroll/salary_rule_categories/index",
        model: "salary_rule_categories",
      },
    ],
    "recruitment [Config]": [
      {
        text: "Config Degrees",
        value: "config_degrees",
        href: "recruitment/config_degrees/index",
        model: "config_degrees",
      },
      {
        text: "Config Sources",
        value: "config_sources",
        href: "recruitment/config_sources/index",
        model: "config_sources",
      },
    ],
    "appraisal [Config]": [
      {
        text: "Appraisal Plans",
        value: "appraisal_plans",
        href: "appraisal/appraisal_plans/index",
        model: "appraisal_plans",
      },
    ],
    "Job Queue [Config]": [
      {
        text: "Job instances",
        value: "job_instances",
        href: "job_queue/job_instances/index",
        model: "job_instances",
      },
      {
        text: "Job run status",
        value: "job_run_status",
        href: "job_queue/job_run_status/index",
        model: "job_run_status",
      },
      {
        text: "Job run details",
        value: "job_run_details",
        href: "job_queue/job_run_details/index",
        model: "job_run_details",
      },
    ],
    "Analytics [Config]": [
      {
        text: "Report Categories",
        value: "report_categories",
        href: "analytics/report_categories/index",
        model: "report_categories",
      },
    ],
    "Documentation [Config]": [
      {
        text: "Comments",
        value: "comments",
        href: "documentation/comments/index",
        model: "comments",
      },
    ],
    "Kpi [Config]": [
      {
        text: "Notification Related To",
        value: "notification_related_to",
        href: "kpi/notification_related_to/index",
        model: "notification_related_to",
      },
      {
        text: "Goal Types",
        value: "goal_types",
        href: "kpi/goal_types/index",
        model: "goal_types",
      },
    ],
    "leave_management [Config]": [
      {
        text: "Leave Type",
        value: "leave_type",
        href: "leave_management/leave_type/index",
        model: "leave_type",
      },
    ],
    "license_management [Config]": [
      {
        text: "License Domains",
        value: "license_domains",
        href: "license_management/license_domains/index",
        model: "license_domains",
      },
      {
        text: "License Ips",
        value: "license_ips",
        href: "license_management/license_ips/index",
        model: "license_ips",
      },
    ],
  },
  grid_primary_keys: [],
  editor: [
    { text: "textarea", value: "textarea" },
    { text: "markup", value: "markup" },
    { text: "WYSIWYG", value: "WYSIWYG" },
    { text: "code editor", value: "code_editor" },
    { text: "form builder", value: "form-builder" },
  ],
  permissions: [
    { text: "INHERIT", value: 0 },
    { text: "VIEW", value: 4 },
    { text: "EDIT", value: 2 },
    { text: "DELETE", value: 1 },
    { text: "VIEW+EDIT", value: 6 },
  ],
  radio_show_sequence_column: [
    { text: "Yes", value: 1 },
    { text: "No", value: 0 },
  ],
  radio_show_labels: [
    { text: "Yes", value: 1 },
    { text: "No", value: 0 },
  ],
  radio_layout: [
    { text: "table", value: "table" },
    { text: "div", value: "div" },
    { text: "ul", value: "ul" },
  ],
  radio_is_first_level_filter: [
    { text: "Yes", value: 1 },
    { text: "No", value: 0 },
  ],
};

export default lookups;

import { useContext, useState, useEffect } from "react";
import { MenuContext } from "../contexts/MenuContext";
import { LayoutContext } from "../contexts/LayoutContext";
import styles from "./Menu.module.css";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import { removeElement, getElement } from "../helper";
const Menu = () => {
  const { showMenu, anchorPoint, path, setShowConfig } =
    useContext(MenuContext);
  const { layout, setLayout } = useContext(LayoutContext);
  const [element, setElement] = useState();

  useEffect(() => {
    console.log(layout, path);
    setElement(getElement(layout, path));
  }, [layout, path]);

  return (
    <>
      {showMenu && (
        <div
          // className={styles.menu}
          style={{
            top: anchorPoint.y,
            left: anchorPoint.x,
            position: "absolute",
            width: 200,
            background: "#ffffff",
            borderStyle: "solid",
            borderWidth: 2,
            borderColor: "#f5f5f5",
            zIndex: 1501,
          }}
        >
          <MenuList>
            <Typography variant="h6">
              {element?.template} {element?.id}{" "}
            </Typography>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem
              onClick={() => {
                console.log(`Config ${path}`);
                setShowConfig(true);
              }}
            >
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Config</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={async () => {
                console.log("setLayout");
                setLayout(await removeElement(layout, path));
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuList>
        </div>
      )}
    </>
  );
};

export default Menu;

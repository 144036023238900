import React from "react";
import { useDrop } from "react-dnd";
import { SIDEBAR_ITEM, CONTAINER, COMPONENT, DEBUG } from "../constants";

const DropZone = ({
  filled,
  vertical,
  handleDrop,
  children,
  path,
  style,
  fillRest,
  colStart,
  grid,
}) => {
  const ACCEPTS = [SIDEBAR_ITEM, CONTAINER, COMPONENT];
  const [{ isOver, canDrop }, ref] = useDrop({
    accept: ACCEPTS,
    drop: (item, monitor) => {
      if (monitor.didDrop()) return;
      handleDrop(item, path, monitor.getItemType());
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item, monitor) => {
      if (!item.path) return true;

      const zonePath = path.split("-");
      const itemPath = item.path.split("-");

      // zonepath is children (zonepath len > itempath len)
      if (
        zonePath.length > itemPath.length &&
        zonePath.slice(0, itemPath.length).join("-") === item.path
      ) {
        return false;
      }

      // zonepath is same level (zonepath len === itempath len)
      if (zonePath.slice(0, -1).join("-") === itemPath.slice(0, -1).join("-")) {
        let itemLast = Number(itemPath.slice(-1)[0]);
        let zoneLast = Number(zonePath.slice(-1)[0]);
        if (zoneLast === itemLast || zoneLast === itemLast + 1) {
          return false;
        }
      }
      return true;
    },
  });
  return (
    <div
      style={{
        width: filled || fillRest || !vertical || grid ? "100%" : "40px",
        height: filled || vertical ? "100%" : "30px",
        minHeight: (filled || vertical) && "30px", // might mess things up***
        backgroundColor: "rgb(230,230,240)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        opacity: (isOver || DEBUG) && (DEBUG || canDrop) ? 1 : 0,
        borderRadius: 10,
        flex: filled && "1 1 auto", // Fixes dropzone spacing
        gridColumn: filled
          ? "1 / -1"
          : fillRest
          ? `${colStart} / -1`
          : "auto / span 1",
        gridRow: filled ? "1 / -1" : "auto / span 1",
      }}
      ref={ref}
    >
      {/* {DEBUG &&
        (isOver || DEBUG) &&
        (canDrop ? `Drop at ${path}` : `Can't drop ${path}`)} */}
    </div>
  );
};
DropZone.displayName = "DropZone";

export default DropZone;

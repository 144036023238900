import { createContext, useState } from "react";

const MenuContext = createContext({
  showMenu: false,
  setShowMenu: () => {},
  anchorPoint: {},
  setAnchorPoint: () => {},
  path: "",
  setPath: () => {},
  showConfig: false,
  setShowConfig: () => {},
});

const MenuProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [path, setPath] = useState("");
  const [showConfig, setShowConfig] = useState(false);
  return (
    <MenuContext.Provider
      value={{
        showMenu,
        setShowMenu,
        anchorPoint,
        setAnchorPoint,
        path,
        setPath,
        showConfig,
        setShowConfig,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export { MenuContext, MenuProvider };

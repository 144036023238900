import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { getName } from "../../helper"

const Subform = ({item}) => {
  return (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={100}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Subform: {item.properties.sub_form_model_class} - {item.properties.sub_form_name}
    </Skeleton>
  );
};

export default Subform;

import React from "react";
import TextField from "@mui/material/TextField";
import { getName } from "../../helper";

const Textarea = ({ item }) => {
  return (
    <TextField
      helperText={item.properties?.help}
      id="outlined-textarea"
      label={getName(item, "TextArea")}
      multiline
      rows={4}
      fullWidth
    />
  );
};

export default Textarea;

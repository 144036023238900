import React, { useContext, useState } from "react";
import { MenuContext } from "../contexts/MenuContext";
import DropZone from "./DropZone";
import { useDrag } from "react-dnd";
import { CONTAINER, CONTAINER_DICT } from "../constants";
import { isGrid } from "../helper";

const Container = ({ item, handleDrop, cantDrag, path, children }) => {
  const {
    template,
    id,
    properties: { colspan },
    extraConf: { direction, parentWidth },
  } = item;

  const Component = CONTAINER_DICT[template];

  const { setShowMenu, setAnchorPoint, setPath } = useContext(MenuContext);

  const [ , dragRef] = useDrag({
    type: CONTAINER,
    item: { type: CONTAINER, id, direction, path },
    canDrag: (_) => !cantDrag,
  });

  const [style, setStyle] = useState(defaultStyle);

  //const opacity = isDragging ? 0 : 1;

  return (
    <div
      ref={dragRef}
      style={{
        ...style,
        gridColumn: `auto / span ${colspan * parentWidth + colspan - 1}`,
        padding: isGrid(template) ? "20px 0" : 20,
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        console.log(`${template}-${id}`);
        setShowMenu(true);
        setAnchorPoint({ x: e.pageX, y: e.pageY });
        setPath(path);
      }}
    >
      {/*<Typography>{item.sequence}</Typography>*/}
      <Component item={item} setStyle={setStyle}>
        {children}
        {!children && (
          <DropZone filled path={`${path}-0`} handleDrop={handleDrop} />
        )}
      </Component>
    </div>
  );
};

const defaultStyle = {
  width: "100%",
  height: "auto",
  minHeight: "300px",
  //maxWidth: 1000,
  background: "#ffffff",
  borderWidth: 2,
  borderColor: "#DFE0EB",
  borderStyle: "solid",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-Start",
  boxSizing: "border-box",
  cursor: "move",
  gridRow: "auto / span 1",
};
export default Container;

import React, { useEffect, useState } from "react";
import { DEBUG, COL_WIDTH, MAX_COLS } from "../../constants";
import Typography from "@mui/material/Typography";
import styles from "../../styles";
import { getName } from "../../helper";

const Grid = ({ item, children }) => {
  const {
    extraConf: { maxCols, colWidth },
  } = item;
  const cols = colWidth * maxCols + maxCols + 1;
  const childrenArr = React.Children.toArray(children);

  useEffect(() => {
    console.log(children);
  }, [children]);
  
  return (
    <>
      <Typography sx={{ ml: 4, mb: 2 }} variant="h6">
        {getName(item, "Block")}
      </Typography>
      {DEBUG && `GRID-${item.id}`}
      <div
        id={`grid-${item.id}`}
        style={{
          ...styles.container,
          // flexDirection: "row",
          display: "grid",
          gridTemplateColumns: `repeat(${cols},1fr)`,
          gridAutoRows: "auto",
          rowGap: "2em",
          alignItems: "flex-start",
        }}
      >
        {children}
        {/* {childrenArr[0].props.children ? (
          <>
            {childrenArr[0].props.children[0].map((child) => {
              console.log(child);
              return child;
            })}
            {childrenArr[0].props.children[1]}
          </>
        ) : (
          children
        )} */}
      </div>
    </>
  );
};

Grid.displayName = "Grid";
/*
 _ _ _
|x|x|x|
|x|_|_|
|_|_|_|
*/
export default Grid;

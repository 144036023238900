import React, { useEffect } from "react";
import { DEBUG } from "../../constants";
import { getName } from "../../helper";
import styles from "../../styles";
import Typography from "@mui/material/Typography";

const Block = ({ item, children, setStyle }) => {
  return (
    <>
      <Typography sx={{ml: 4}} variant="h6">
        {getName(item, "Block")}
      </Typography>
      {DEBUG && `Block-${item.id}`}
      <div style={{ ...styles.container, flexDirection: "column" }}>
        {children}
      </div>
    </>
  );
};

Block.displayName = "Block";
export default Block;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import logo from "../logo.png";
import styles from "./Login.module.css";
import * as api from "../api";

const Login = () => {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [errors, setErrors] = useState(false);

  let navigate = useNavigate();

  const handleSubmit = async () => {
    const res = await api.login(user, pass);
    if (res?.errors?.length > 0) {
      setErrors(true);
    } else {
      navigate("/");
    }
  };

  return (
    <Box className={styles.container}>
      <Box
        className={styles.card}
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
        }}
      >
        <img src={logo} alt="logo" style={{ margin: 20 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p style={{ textAlign: "center" }}>
              Sign in with your Fiyge credentials
            </p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={user}
              onChange={(e) => {
                setUser(e.target.value);
                setErrors(false);
              }}
              fullWidth
              label="Username"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={pass}
              onChange={(e) => {
                setPass(e.target.value);
                setErrors(false);
              }}
              fullWidth
              label="Password"
              type="password"
              error={errors}
              helperText={errors && "Incorrect credentials"}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Login;

import React, { Fragment, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import { MenuContext } from "../../contexts/MenuContext";
import { LayoutContext } from "../../contexts/LayoutContext";

const FiygeConfig = ({ element }) => {
  const { showConfig, setShowConfig, path } = useContext(MenuContext);
  const { layout, setLayout } = useContext(LayoutContext);
  const [config, setConfig] = useState(null);

  // {key: value} => [["key","value"]]
  useEffect(() => {
    if (showConfig) {
      let conf = {};
      if (path && showConfig) {
        const ignore = ["children", "extraConf", "properties"];
        Object.keys(element)
          .filter((key) => !ignore.includes(key))
          .forEach((key) => {
            let value = element[key];
            conf[key] =
              typeof value === "object" ? JSON.stringify(value) : value;
          });
      }
      setConfig(conf);
    }
  }, [path, showConfig, element]);

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems="center"
        style={{
          marginTop: 10,
          width: "100%",
          maxHeight: 600,
          overflowY: "auto",
        }}
      >
        {config &&
          Object.keys(config).map(
            (key, idx) =>
              typeof config[key] !== "function" && (
                <Fragment key={idx}>
                  <Grid item xs={12}>
                    <TextField
                      variant="filled"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      label={key}
                      value={config[key]}
                    />
                  </Grid>
                </Fragment>
              )
          )}
      </Grid>
      <DialogActions>
        <Button onClick={() => setShowConfig(false)} autoFocus>
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default FiygeConfig;

import React, { useState } from "react";
import MuiRadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { getName } from "../../helper";

const RadioGroup = ({ item, children }) => {
  const {
    extraConf: { specialConfig },
  } = item;
  const [value, setValue] = useState(specialConfig[0]);

  return (
    <FormControl>
      <FormLabel>{getName(item)}</FormLabel>
      <MuiRadioGroup
        row
        value={value}
        onChange={(e) => setValue(e.target.value)}
        helperText={item.properties?.help}
      >
        {specialConfig.map((item, idx) => (
          <FormControlLabel
            key={idx}
            value={item}
            control={<Radio />}
            label={item}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default RadioGroup;
